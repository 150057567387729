import { useGetEssaysQuery } from './essaysApiSlice'
import EssayToView from './EssayToView'
import { Container, Table, Button, Col, Row, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const PublishedEssaysList = () => {


  const {
    data: essays,
    isLoading,
    isSuccess,
    isError,
    error

  } = useGetEssaysQuery()


  let content 

  if (isLoading) content =
  
  <div className="spinner">
    <Spinner animation="border" />
  </div>

  if(isError) {
  console.log(error)
    content = 
    <>
    <Container>
    <br />
    {/* <h4 className="errmsg">{error?.data?.message}</h4> */}
    <p>It looks like no one has published an essay yet for others to read. Why not take the lead and share one of yours? </p>
    <Link to="/dash/essays/">
      <Button>
        Go to my essays
      </Button>
    </Link>
    </Container>
    </>
  
  }

  if (isSuccess) {
    const { ids, entities } = essays 

    let filteredIds

    filteredIds = ids.filter(essayId => entities[essayId].published === true)

    if (filteredIds.length === 0) {

      content = 
      <>
      <Container>
      <br />
      {/* <h4 className="errmsg">{error?.data?.message}</h4> */}
      <p>It looks like no one has published an essay yet for others to read. Why not take the lead and share one of yours? </p>
      <Link to="/dash/essays/">
        <Button>
          Go to my essays
        </Button>
      </Link>
      </Container>
      </>

    } else {
  

    const tableContent = ids?.length && filteredIds.map(essayId => <EssayToView key={essayId} essayId={essayId} />)
  
    content = (
       
      <Container>
        <Row>
        <Col>
        <br />
        <p>Below is a list of essays that other users have shared for the YakkaGamsat community to view and comment on.</p>
        <Table striped>
          <thead>
            <tr>
              <th>Essay Snippet</th>
              <th>Written by</th>
              <th>Date</th>
              <th>Theme</th>
              <th>Likes</th>
              <th>Comments</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tableContent}
          </tbody>
        </Table>
        </Col>
        </Row>
      </Container>
    )
    }
  }
  return content 
}

export default PublishedEssaysList