import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import './stripe-styles.css'
import { useUpdateEssayMutation } from "../essays/essaysApiSlice";

export default function EssayCorrectCheckoutForm({essay}) {
  const stripe = useStripe();
  const elements = useElements();


  const [updateEssay, {
    // isLoading,
    isSuccess,
    isError,
    // error
}] = useUpdateEssayMutation()

if (isSuccess) {
    console.log("correctionDue Set on Essay")
}

  const [message, setMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
            // Make sure to change this to your payment completion page
            // return_url: 'http://localhost:3000/dash/payments/completion',
            return_url: 'https://yakkagamsat.com/dash/payments/completion',
        },
        redirect: "if_required"
    });

    if (error) {
      setMessage(error.message);
    } else if (paymentIntent && paymentIntent.status === "succeeded") {
      await updateEssay({"id": essay.id, "essayText": essay.essayText, "correctionDue": true })
      setMessage("Payment Status: " + paymentIntent.status + " 🎉. Your essay is now in our queue for feedback. We aim to achieve a turnaround time of less than 48 hours.");
      if (isError) {
        setMessage("There was an error updating your account details, please contact us at yakkagamsat@gmail.com and we will rectify this as soon as possible.");
      }
    } else {
        setMessage("Unexpected state")
    }

    setIsProcessing(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <PaymentElement id="payment-element" />
      <br />
      <button className="button-stripe" disabled={isProcessing || !stripe || !elements} id="submit">
        <span id="button-text">
          {isProcessing ? "Processing ... " : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}