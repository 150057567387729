import React from 'react'

import { Container } from 'react-bootstrap';



const Completion = () => {

let content

  content =
  <>
  <br />
  <Container>
  <h3>Boom!</h3>
  <p>
    All done, you're now a Pro!
  </p>
  </Container>
    </>

  return content
}

export default Completion