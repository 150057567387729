import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router-dom"
import { useGetQuotesQuery } from "./quotesApiSlice"
import { memo } from "react"

const Quote = ({ quoteId }) => {
    
    const { quote } =useGetQuotesQuery("quotesList", {
        selectFromResult: ({ data }) => ({
            quote: data?.entities[quoteId]
        }),
    })
    
    const navigate = useNavigate()

    if (quote) {
        const handleEdit = () => navigate(`/dash/quotes/${quoteId}`)

        
        
        return (
            <tr className="table__row quote">
                <td className={`table__cell`}>{quote.quoteText}</td>
                <td className={`table__cell`}>{quote.author}</td>
                <td className={`table__cell`}>{quote.themeText}</td>
                <td className={`table__cell`}>
                    <button
                        className="icon-button table__button"
                        onClick={handleEdit}
                    >
                        <FontAwesomeIcon icon={faPenToSquare} />
                    </button>
                </td>
            </tr>
        )


    } else return null

}
const memoizedQuote = memo(Quote)
export default memoizedQuote