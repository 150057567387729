
import { useNavigate } from "react-router-dom"
import { useGetEssaysQuery } from "./essaysApiSlice"
import { memo } from "react"
import { Button } from "react-bootstrap"

const Essay = ({ essayId }) => {
    
    const { essay } = useGetEssaysQuery("essayList", {
        selectFromResult: ({ data }) => ({
            essay: data?.entities[essayId]
        })
    })

    const navigate = useNavigate()

    if (essay) {
        const handleEdit = () => navigate(`/dash/essays/${essayId}`)
    
        let date = new Date(essay.createdAt)
        date = date.toDateString().split(' ').slice(1).join(' ')


        return (
            <tr onClick={handleEdit}>
                <td>{essay.essayText.substring(0,50)} ...</td>
                <td>{date}</td>
                <td>{essay.theme}</td>
                <td>
                    <Button
                        variant="outline-dark"
                        onClick={handleEdit}
                    >
                        View and Edit
                    </Button>
                </td>
      
            </tr>
    
        )


    } else return null

}
const memoizedEssay = memo(Essay)
export default memoizedEssay