import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom"
import { useGetThemesQuery } from "./themesApiSlice"
import { memo } from "react"

const Theme = ({ themeId }) => {
    
    const { theme } =useGetThemesQuery("themesList", {
        selectFromResult: ({ data }) => ({
            theme: data?.entities[themeId]
        }),
    })
    
    const navigate = useNavigate()

    if (theme) {
        const handleEdit = () => navigate(`/dash/themes/${themeId}`)
        
        return (
            <tr className="table__row theme">
                <td className={`table__cell`}>{theme.themeText}</td>
                <td className={`table__cell`}>{theme.description}</td>
                <td className={`table__cell`}>
                    <Button
                        variant="outline-dark"
                        onClick={handleEdit}
                    >
                        View and Edit
                    </Button>
                </td>
            </tr>
        )


    } else return null

}
const memoizedTheme = memo(Theme)
export default memoizedTheme