
import { useNavigate } from "react-router-dom"
import { useGetEssaysQuery } from "./essaysApiSlice"
import { memo } from "react"
import { Button } from "react-bootstrap"
import { useGetUsersQuery } from "../users/usersApiSlice"

const ProEssayToView = ({ essayId }) => {
    
    const { essay } = useGetEssaysQuery("essayList", {
        selectFromResult: ({ data }) => ({
            essay: data?.entities[essayId]
        })
    })

    const {user} = useGetUsersQuery("userList", {
        selectFromResult:({ data }) => ({
            user: data?.entities[essay?.user]
        })
    })

    const navigate = useNavigate()

    if (essay) {
        const handleEdit = () => navigate(`/dash/essays/published/${essayId}`)

        return (
            <tr onClick={handleEdit}>
                <td>{essay.essayText.substring(0,50)} ...</td>
                <td>{user?.username}</td>
                <td>{essay.userScore}</td>
                <td>{essay.theme}</td>
                <td>
                    <Button
                        variant="outline-dark"
                        onClick={handleEdit}
                    >
                        View
                    </Button>
                </td>
            </tr>
    
        )


    } else return null

}
const memoizedEssay = memo(ProEssayToView)
export default memoizedEssay