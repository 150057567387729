import { useNavigate } from "react-router-dom"
import { useGetUsersQuery } from "./usersApiSlice"
import { memo } from "react"
import { Button } from "react-bootstrap"

const User = ({ userId }) => {
    
    const { user } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            user: data?.entities[userId]
        }),
    })

    const navigate = useNavigate()

    if (user) {
        const handleEdit = () => navigate(`/dash/users/${userId}`)

        let accountCreationDate = new Date(user.createdAt)
        accountCreationDate = accountCreationDate.toDateString().split(' ').slice(1).join(' ')

        const userRolesString = user.roles.toString().replaceAll(',', ', ') //Removed this ? error

        return (
            <tr onClick={handleEdit} className="table__row user">
                <td>{user.username}</td>
                <td>{user.email}</td>
                <td>{accountCreationDate}</td>
                <td>{user.verified ? "Verified" : "Awaiting Verification"}</td>
                <td>{userRolesString}</td>
                <td>
                    <Button
                       variant="outline-secondary"
                        onClick={handleEdit}
                    >
                       View User
                    </Button>
                </td>
            </tr>
        )


    } else return null

}
const memoizedUser = memo(User)
export default memoizedUser