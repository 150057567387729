import { useState, useEffect } from "react"
import { useAddNewThemeMutation } from "./themesApiSlice"
import { useNavigate } from "react-router-dom"
import { Container, Form, Button } from "react-bootstrap"
import useAuth from "../../hooks/useAuth"

const NewThemeForm = () => {

    const navigate = useNavigate()
    const {isSuperuser} = useAuth()
    
    if (!isSuperuser) navigate('/dash')

    const [addNewTheme, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewThemeMutation()


    const [themeText, setThemeText] = useState('')
    const [description, setDescription] = useState('')
    

    useEffect(() => {
        console.log(isSuccess)
        if (isSuccess) {
            setThemeText('')
            setDescription('')
            navigate('/dash/themes/new')
        }

    }, [isSuccess, navigate])

    const onThemeTextChanged = e => setThemeText(e.target.value)
    const onDescriptionChanged = e => setDescription(e.target.value)


    const onSaveThemeClicked = async (e) => {
        if (description) {
            await addNewTheme({ themeText, description })
        } else {
            await addNewTheme({ themeText })
        }
    }

    let canSave
    if (themeText) {
        canSave = [themeText].every(Boolean) && !isLoading
    } 

    const errClass = (isError) ? "errmsg" : "offscreen"
    const validThemeClass = !themeText ? 'form__input--incomplete' : ''
    
    const errContent = (error?.data?.message) ?? ''


    const content = (
      
    <>
        <p className={errClass}>{errContent}</p>
<Container>
        <Form className="form" onSubmit={e => e.preventDefault()}>
            <div className="form__title-row">
                <h2>Add a New Theme</h2>
            </div>
            <label className="form__label" htmlFor="themeText">
                Theme: </label>
            <Form.Control
                className={`form__input ${validThemeClass}`}
                id="themeText"
                text="themeText"
                type="text"
                autoComplete="off"
                value={themeText}
                onChange={onThemeTextChanged}
            />
            <br />
            
            <label className="form__label" htmlFor="description">
                Description: </label>
            <Form.Control
                className={`form__input`}
                id="description"
                text="description"
                type="description"
                value={description}
                onChange={onDescriptionChanged}
            />
            <br />
                    <Button
                        title="Save"
                        onClick={onSaveThemeClicked}
                        disabled={!canSave}
                    >
                        Save
                    </Button>
        </Form>
        </Container>
    </>
    )

    return content
}
export default NewThemeForm