import React from 'react'
import { Alert, Container, Accordion, Row, Badge, Card, Button, Col } from 'react-bootstrap'
import useAuth from '../../hooks/useAuth'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const Section1 = () => {

    const {isPro} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (!isPro) navigate ('/dash')
    })

  return (
    <>
    <Container>
        <br />
        <h2>Section 1</h2>
        <Alert variant="warning">
            Section 1 is the first challenge you will face on exam day. It is all about literature, the arts, visual interpretation and the ability to understand subtleties within different texts and images. The strategies listed below enabled me to jump from 55 in this section to 70. It's not rocket science. Instead, it's all about developing the right habits so that when it comes to exam time, the content is much more digestible and less alien to the untrained mind. I became much more confident in both my comprehension of the prompts and in determining exactly what the questions were asking.
        </Alert>
        
    <Accordion defaultActiveKey="">
        <Accordion.Item eventKey="0">
            <Accordion.Header>Don't be Deceived</Accordion.Header>
            <Accordion.Body>
            <p>
            On the surface, section 1 is a simple challenge of "how well can you read and understand the message on the page in front of you?" If you are a native English-speaker, it is natural to think that you will be able to read, and understand, anything written in English. The GAMSAT will humble those who stick to this belief.
            </p>
            <p>
            The real trick, and challenge, is possessing the ability to reason effectively. An easy fallacy to fall for is the one that this section is a giveaway - confident in the fact that English is your mother tongue and you will understand anything the exam puts before you. I’d recommend dropping any temptation to hold onto this belief, as it will likely result in an underperformance on your part. Candidates are required to answer several multiple choice questions (MCQ) in a short period of time across many different contexts; one minute you’re trying to figure out the message of a cartoon, the next you’re skimming through an excerpt from an early 20th century novel with archaic language to figure out exactly how the author describes the weather that day. This is more challenging than it sounds, as the available answers to choose from can all seem quite similar - and you’ll be asked to select <b>the single answer</b> that is most correct.
            </p>

            <p>
                Before I get into the how, here is the reason why you should give this section more time during your preparation, and it’s simple: unless you develop an active interest in fiction and non-fiction books, poetry, cartoons, journalism, psychology and the social sciences, it is likely you will face significant challenge in the exam. More importantly, and something you will see reiterated multiple times across YakkaGamsat: your GAMSAT result is the sum of all three sections. A lot of people seem to disproportionately fret over the science of section 3, but if sections 1 and 2 are weak, the overall average is skewed against you. Sections 1 and 2 should be where the low hanging fruit must be grabbed. The pieces of advice below will assist you in better execution of section 1. 
            </p>
            </Accordion.Body>
        </Accordion.Item> 
        <Accordion.Item eventKey="1">
            <Accordion.Header>Read Widely</Accordion.Header>
            <Accordion.Body>
            <p>
                To perform well, you’ll want to be able to comfortably read and understand all the previously mentioned genres as much as possible, and the best way to achieve this, unsurprisingly, is by doing exactly that. Perhaps you are an avid reader of non-fiction books and this genre is easy for you to comprehend. You’re off to a great start, as you will likely find the passages and questions of this kind to be accessible and the correct answers may flow to you with little thought. Let's say that, on the other hand, you might dislike fictional content. Your dislike for fictional content will likely predispose you to being less efficient at understanding it in the exam, given your unfamiliarity with it, and therefore your performance will lag in this instance. You should seek to increase the amount you interact with the genres you dislike, and this newly- engendered familiarity will have a positive effect on your reasoning. The more you read, the better you will become. When I decided to take a second shot at the GAMSAT, I purchased numerous fictional books which I had little interest in before reading them, and read them cover to cover. Following this, I looked up some reviews and analyses online to see if I had interpreted it accurately - it is all a learning process.
            </p>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
            <Accordion.Header>Speeding Your Reading</Accordion.Header>
            <Accordion.Body>
                <p>
                    Section 1 requires candidates to read lengthy passages and answer the related questions at speed (about 90 seconds per question), and so the need to read efficiently is self-evident.
                </p>
                <p>
                    There are many techniques to ‘speed-reading’ which may improve your ability to get through the content more quickly, but it is important to acknowledge any trade-off in comprehension when attempting these.
                </p>
                <p>
                    My advice, so as to reduce the likelihood of comprehension loss, is to increase the amount you read in the lead-up to the exam. We already read a lot on a day-to-day basis (yrd, even 'non-readers'), but this is often in the form of bitty information, which for most people is via social media, and these are rarely longer than a few hundred characters. In preparing for section 1, you should develop your ability to concentrate for longer periods so that you are comfortable reading a relatively lengthy passage without multiple lapses of concentration. I find that when I haven’t been reading consistently for a couple of months, it takes me a while to regain the ability to sit in a concentrated manner, but with attempts at simply reading a book, it eventually comes back. 
                </p>
                <p>
                    Now, if you feel tempted to develop this by reading as many tweets as you can in a short space of time, let's maybe shelve that idea for the time being. A more productive approach is to read content that is of similar length to the passages you will face in the exam. Newspaper articles or the abstracts of scientific papers are prime candidates. In terms of newspapers, for Australian candidates, if you're a student you can get offers on The Australian. If you are a student in the Republic of Ireland, you are eligible for free access to an online subscription for The Irish Times. Make use of such opportunities to read as you prepare for the exam. It will not only be beneficial to you by increasing your reading proficiency, but it will also increase your awareness of current affairs, and this is of great benefit for section 2.
                </p>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
            <Accordion.Header>Approaching Section 1 Content</Accordion.Header>
            <Accordion.Body>
                <p>
                The previous pieces of advice will improve your ability to understand different forms of content and to do so efficiently. Now, I will discuss the ways in which you can methodically tackle the exam questions. 
                </p>

                <p>
                The question blocks come in different shapes and sizes. In one unit you may only be required to answer one question on an artist’s painting, but another unit might have four to five questions based on a page-long passage from a written play. For the case of the artists painting, you have time to read the image and to cross-reference it with the available answers. In the case of the lengthy passage, time is of the essence and efficiency is necessary. The approach I find most useful in this second instance is the following:

                <ol>
                    <li>
                        Before reading the passage itself, skim over the related questions. This will provide you with a bit of context as to what the passage might be about. More importantly, you will be cognisant of the types of information the questions are asking you to find.
                    </li>
                    <li>
                        Start reading the passage. If you come across a piece of information that relates to a question, mark it and answer the question if necessary.
                    </li>
                    <li>
                        Many questions will indicate where to find a given answer in the passage by providing a line number in parentheses at the end of the question. If, after having read the passage, you are unsure of an answer, find the line number in question and read the sentence in question, as well as the sentence above and below. Covering these three sentences should provide you with the answer.
                    </li>
                    <li>
                        If after using this technique you are still unsure of the answer, try to eliminate those answers which do not think are correct, and make a guess at the remaining answers. You will not know everything on the exam, and that is OK. It is better to guess with the possibility of getting the answer correct, than to leave an answer option empty - reducing a 25% chance of answering correctly to a fat 0%.
                    </li>
                </ol>
            </p>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
            <Accordion.Header>Be Resourceful</Accordion.Header>
            <Accordion.Body>
                There are several online resources which helped me to develop my language skills in preparation for the GAMSAT. First, using a website such as vocabulary.com enabled me to discover or rediscover words, and to better understand the meaning of words, even ones we use in everyday conversation. Expanding your lexicon can only be additive to your preparation for this exam, and it can have a beneficial impact across all three sections.

                <ul>
                    <li>
                        Crash Course
                    </li>
                    <li>
                        Read Theory
                    </li>
                    <li>
                        Poetry
                    </li>
                    <li>
                        Vocabulary.com
                    </li>
                </ul>

            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
    <hr />
    
    
    <Row>
            <p>Now that you're familiar with Section 1, check the links below for further tips on exam strategy.</p>
            <h6>Preparation Strategies Per Section <Badge bg="secondary">Coming Soon</Badge></h6> 
            
            <Col sm={4}>
            <Card bg="light">
                 
            <Card.Body>
                        <Card.Title>Starting Off</Card.Title>
                        <Card.Text>
                            Learn the ABCs of GAMSAT Preparation. 
                        </Card.Text>
                        <Link to="/dash/strategy/startingoff">
                            <Button variant="primary">
                                Starting Off Strategy
                            </Button>
                        </Link>
                    </Card.Body>
            </Card>
            <br />
            </Col>
            <Col sm={4}>
            <Card bg="light">
                 
            <Card.Body>
                        <Card.Title>Section 2</Card.Title>
                        <Card.Text>
                            Methods to ensure you will write excellent responses to the prompts on exam day. 
                        </Card.Text>
                        <Link to="/dash/strategy/section2">
                            <Button variant="primary">
                                Master Section 2
                            </Button>
                        </Link>
                    </Card.Body>
            </Card>
            <br />
            </Col>
            <Col sm={4}>
            <Card bg="light">
                 
            <Card.Body>
                        <Card.Title>Section 3</Card.Title>
                        <Card.Text>
                                The dos and don'ts of preparing for the most feared section of the exam.
                        </Card.Text>
                        {/* <Link to="/dash/strategy/section3"> */}
                            <Button disabled variant="primary">
                                Coming Soon
                            </Button>
                        {/* </Link> */}
                    </Card.Body>
            </Card>
            </Col>
            </Row>

    </Container>
    </>
  )
}

export default Section1