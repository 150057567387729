import React, { useState } from 'react';
import { Form, Button, Container, Table, Spinner } from 'react-bootstrap';
import { useGetCommentsQuery, useAddNewCommentMutation } from '../comments/commentsApiSlice';
import useAuth from '../../hooks/useAuth';
import {Comment} from '../comments/Comment'
// import { useUpdateEssayMutation } from './essaysApiSlice';

function CommentSection( {essay} ) {

  const {username, userId} = useAuth()
  const [newComment, setNewComment] = useState('');
  
  const {
    data: comments,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetCommentsQuery()
  
  let content 
  
  if (isLoading) content = 

  <div className="spinner">
    <Spinner animation="border" />
  </div> 

  const [addNewComment, {
    // isAddLoading,
    isAddSuccess,
    // isAddError,
    // addError
}] = useAddNewCommentMutation()


  const handleSubmit = async (e) => {
    e.preventDefault();
    
    await addNewComment({"commentText": newComment, "user": userId, username, "essay": essay._id})
    if (isAddSuccess) {
      console.log("comment added")
    }
    setNewComment('')
  }

  
  if(isError) {
    console.log(error)
    content = 
    <>
    <Container>
        
        <Form class="form" onSubmit={handleSubmit}>
        
          Be the first to write a reply:
          <Form.Control as="textarea" rows={4}
            type="text"
            value={newComment}
            onChange={e => setNewComment(e.target.value)}
          />
          <br/> 
          <Button type="submit">Reply</Button>
      </Form>
      </Container>
    </>
  }

  if (isSuccess) {
    const { ids, entities } = comments 

      let filteredIds = ids.filter(comment => entities[comment].essay === essay._id)
    

    const tableContent = ids?.length && filteredIds.map(commentId => <Comment key={commentId} commentId={commentId} />)

content = (
    <>
      <Container>
        
        <Table striped>
          <tbody>
            {tableContent}
          </tbody>
        </Table>
      </Container>
      <Container>
        
        <Form className="form" onSubmit={handleSubmit}>
        
          Write a reply:
          <Form.Control as="textarea" rows={4}
            type="text"
            value={newComment}
            onChange={e => setNewComment(e.target.value)}
          />
          <br/> 
          <Button variant="warning" type="submit">Reply</Button>
      </Form>
      </Container>
  </>
  
  )
      }
  return content
}
export default CommentSection;