import { store } from '../../app/store'
import { quotesApiSlice } from '../quotes/quotesApiSlice'
import { usersApiSlice } from '../users/usersApiSlice'
import { themesApiSlice } from '../themes/themesApiSlice'
import { essaysApiSlice } from '../essays/essaysApiSlice'
import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'

const Prefetch = () => {
    
    useEffect(() => {
        store.dispatch(quotesApiSlice.util.prefetch('getQuotes', 'quotesList', { force: true }))
        store.dispatch(usersApiSlice.util.prefetch('getUsers', 'usersList', { force: true }))
        store.dispatch(themesApiSlice.util.prefetch('getThemes', 'themesList', { force: true }))
        store.dispatch(essaysApiSlice.util.prefetch('getEssays', 'essaysList', { force: true }))
    }, [])

    return <Outlet />
}
export default Prefetch