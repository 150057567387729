import { useGetEssaysQuery } from './essaysApiSlice'
import Essay from './Essay'
import useAuth from '../../hooks/useAuth'
import { Container, Table, Button, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const EssaysList = () => {

  const { isSuperuser, email } = useAuth()

  const {
    data: essays,
    isLoading,
    isSuccess,
    isError,
    error

  } = useGetEssaysQuery()

  let content 

  if (isLoading) content = 
  
  <div className="spinner">
    <Spinner animation="border" />
  </div>
  
  if(isError) {
    console.log(error)
    content = 
    <>
    <Container>
    <br />
    {/* <h4 className="errmsg">{error?.data?.message}</h4> */}
    <p>It looks like you haven't written an essay yet, but now is a great time to get started. </p>
    <Link to="/dash/essays/setup">
      <Button>
        Write an Essay
      </Button>
    </Link>
    </Container>
    </>
  }

  if (isSuccess) {
    const { ids, entities } = essays 

    let filteredIds
    if (isSuperuser) {
      filteredIds = [...ids]
    } else {

      filteredIds = ids.filter(essayId => entities[essayId].email === email)
      
    }

    if (filteredIds.length === 0) {
      content = 
      <>
      <Container>
      <br />
      <p>It looks like you haven't written an essay yet. Give it a go by clicking below.</p>
      <Link to="/dash/essays/setup">
        <Button>
          Write an Essay
        </Button>
      </Link>
      </Container>
      </>

    } else {

    const tableContent = ids?.length && filteredIds.map(essayId => <Essay key={essayId} essayId={essayId} />)
  
    content = (
      
      <Container>
        <br />
        <h3>Your Essays</h3>
        <p>A collection of the essays you have completed from the Essay Practice tool. Feel free to review, edit and share your works of art with other Yakka members.</p>
        <Table striped>
          <thead>
            <tr>
              <th>Essay Content</th>
              <th>Created on</th>
              <th>Theme</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {tableContent}
          </tbody>
        </Table>
      </Container>
    )
    }
  }
  return content 
}

export default EssaysList