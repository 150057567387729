import { useGetQuotesQuery } from "./quotesApiSlice"
import Quote from "./Quote"
import useAuth from "../../hooks/useAuth"
import { useNavigate } from "react-router-dom"
import { Spinner } from "react-bootstrap"
const QuotesList = () => {

  const navigate = useNavigate()
  const {isSuperuser} = useAuth()
  
  if (!isSuperuser) navigate('/dash')
  

  const {
    data: quotes,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetQuotesQuery()

  let content 

  if (isLoading) content = 
  <div className="spinner">
    <Spinner animation="border" />
  </div>

  if(isError) {
    content = <p className="errmsg">{error?.data?.message}</p>
  }

  if (isSuccess) {
    const { ids } = quotes 

    const tableContent = ids?.length 
    ? ids.map(quoteId => <Quote key={quoteId} quoteId={quoteId} />)
    : null 

    content = (
      <table className="table table--quotes">
                <thead className="table__thead">
                    <tr>
                        <th>Quote</th>
                        <th>Author</th>
                        <th>Theme</th>
                        <th>Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </table>
    )
  }


  return content
}

export default QuotesList