import { useGetThemesQuery } from "./themesApiSlice"
import Theme from "./Theme"
import { Table, Container, Spinner } from "react-bootstrap"
import useAuth from "../../hooks/useAuth"
import { useNavigate } from "react-router-dom"

const ThemesList = () => {
    
  const navigate = useNavigate()
  const {isSuperuser} = useAuth()
  
  if (!isSuperuser) navigate('/dash')
  const {
    data: themes,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetThemesQuery('themesList', {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true
})
  let content 

  if (isLoading) content = 
  
  <div className="spinner">
    <Spinner animation="border" />
  </div>
  
  if(isError) {
    content = <p className="errmsg">{error?.data?.message}</p>
  }

  if (isSuccess) {
    const { ids } = themes 

    const tableContent = ids?.length && ids.map(themeId => <Theme key={themeId} themeId={themeId} />)

    content = (
      <Container>
      <Table>
                <thead>
                    <tr>
                        <th >Theme</th>
                        <th >Description</th>
                      
                        <th >Edit</th>
                    </tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </Table>
          </Container>
    )
  }


  return content
}

export default ThemesList