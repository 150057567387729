import { useRef, useState, useEffect } from 'react'
import { useNavigate} from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setCredentials } from './authSlice'
import { useLoginMutation } from './authApiSlice'
import usePersist from '../../hooks/usePersist'
import { Button, Container, Form } from 'react-bootstrap'

import PulseLoader from 'react-spinners/PulseLoader'

export const Login = () => {


    const userRef = useRef()
    const errRef = useRef()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [persist,setPersist] = usePersist(true)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [login, { isLoading }] = useLoginMutation()

    useEffect(() => {
        userRef.current.focus()
    }, [])

    useEffect(() => {
        setErrMsg('');
    }, [email, password])


    const handleSubmit = async (e) => {
        e.preventDefault()
        setPersist(true)
        console.log(persist)
        try {
            const { accessToken } = await login({ email, password }).unwrap()
            dispatch(setCredentials({ accessToken }))
            setEmail('')
            setPassword('')
            navigate('/dash')
        } catch (err) {
            if (!err.status) {
                setErrMsg("An unknown error occurred. Please try again");
            } else if (err.status === 400) {
                setErrMsg('Please enter both an email address and password');
            } else if (err.status === 401) {
                setErrMsg('Sorry, that login attempt failed.');
            } else {
                setErrMsg(err.data?.message);
            }
            errRef.current.focus();
        }
    }

    const handleUserInput = (e) => setEmail(e.target.value)
    const handlePwdInput = (e) => setPassword(e.target.value)


    const errClass = errMsg ? "errmsg" : "offscreen"

    if (isLoading) return <PulseLoader color={"#FFF"} />

    const content = (
        <>
            <Container className='sign-in-container'>
                <Form className="sign-in-form" onSubmit={handleSubmit}>
                <img className="navlogo" src="./YGLogo.png" alt=""/>
                <span className="navBrandText">
                        YakkaGamsat
                    </span>
                <hr />
                    <h4 className='h4-headers'>Sign in</h4>
                    
                    <Form.Control
                        type="email"
                        id="email"
                        placeholder='Email'
                        ref={userRef}
                        value={email}
                        onChange={handleUserInput}
                        autoComplete="off"
                        required
                    />
                <br />
                    <Form.Control
                        type="password"
                        id="password"
                        placeholder='Password'
                        onChange={handlePwdInput}
                        value={password}
                        required
                    />
                    <br />
                <p ref={errRef} className={errClass} aria-live="assertive">{errMsg}</p>
                    <Button type="submit" className="custom-btn" variant='outline-light' onClick={handleSubmit}><span className='custom-button-class'>Continue</span></Button>
                    <br /> <br />
                <a className='smallLinks' href="/forgotpassword">Forgot your password?</a>
                <br /> <br />
                Don't have an account? <a className='smallLinks' href="/signup">Sign Up</a>
                </Form>
                </Container>
                </>
            
    )

    return content
}
export default Login