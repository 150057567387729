import { useState } from "react"
import { useGetThemesQuery } from "../themes/themesApiSlice"
import { useGetQuotesQuery } from "../quotes/quotesApiSlice"
import { Link } from "react-router-dom"
import NewEssayForm from "./NewEssayForm"
import Timer from "./Timer"
import { Accordion, Container, Button, Row, Col, Card, Form, Alert, Tab, Tabs } from "react-bootstrap"
import useAuth from "../../hooks/useAuth"
const EssaySettingsForm = () => {

    const {isPro} = useAuth()

    // This is where the user will select the theme they want to use
    const { themes } = useGetThemesQuery("themesList", {
        selectFromResult: ({ data }) => ({
            themes: data?.ids.map(id => data?.entities[id])
        })
    })
    const { quotes } = useGetQuotesQuery("quotesList", {
        selectFromResult: ({ data }) => ({
            quotes: data?.ids.map(id => data?.entities[id])
        })
    })

    const [essayCount, setEssayCount] = useState(1)
    const [theme, setTheme] = useState('')
    const [isRandomChecked, setIsRandomChecked] = useState(true)
    const [isStarted, setIsStarted] = useState(false)
    const [finalQuotes, setFinalQuotes] = useState('') 
    const [showOrHide, setShowOrHide] = useState('Reveal Theme')
    const [theme2, setTheme2] = useState('')
    const [finalQuotes2, setFinalQuotes2] = useState('') 
    const [activeTaskTab, setActiveTaskTab] = useState("A")
    const [immediateStartTimer, setImmediateStartTimer] = useState(false)

    let shuffledQuotes = []
    
    //Manages when the random checkbox is selected/deselected
    const handleOnChangeRandom = () => {  
        setIsRandomChecked(!isRandomChecked)
      }
    const handleOnChangeImmediateStart = () => {  
        setImmediateStartTimer(!immediateStartTimer)
      }

    const handleEssayCountChange = (e) => {
        setEssayCount(Number(e.target.value))

        if (e.target.value === '2') {
            setIsRandomChecked(true)
        }
    }
    
      const onThemeChanged = e => {
        setTheme(e.target.value)
        
    }

    //When the start essay button is clicked
    const handleOnStarted = () => {

        setIsStarted(!isStarted)

        let chosenTheme = null 

        if (theme) {
            const themeObject = themes?.filter( object => object._id === theme)
            chosenTheme = themeObject[0]
            setTheme(chosenTheme)

        } else {
            if (isPro) {
            chosenTheme = themes[Math.floor(Math.random() * themes?.length)]
            setTheme(chosenTheme)
            
            } else {
            chosenTheme = themes[Math.floor(Math.random() * 10)]
            setTheme(chosenTheme)
            }
        } 
        
        // Get all quotes for selected theme
        const filteredQuotes = quotes?.filter( quote => quote?.themeText === chosenTheme?.themeText)
        
        // shuffle all quotes for selected theme, the first 4 of which will be
        // presented to the user for the essay
        shuffledQuotes = filteredQuotes
            ?.map(value => ({ value, sort: Math.random() }))
            .sort((a, b) => a.sort - b.sort)
            .map(({ value }) => value)

        setFinalQuotes(shuffledQuotes)
        
        if (essayCount === 2) {
            let filteredThemes = themes?.filter( theme => theme !== chosenTheme)
            if (isPro) {
                chosenTheme = filteredThemes[Math.floor(Math.random() * themes?.length)]
                setTheme2(chosenTheme)
                
                } else {
                chosenTheme = filteredThemes[Math.floor(Math.random() * 10)]
                setTheme2(chosenTheme)
                }

                        // Get all quotes for selected theme
                const filteredQuotes = quotes?.filter( quote => quote?.themeText === chosenTheme?.themeText)
                
                // shuffle all quotes for selected theme, the first 4 of which will be
                // presented to the user for the essay
                shuffledQuotes = filteredQuotes
                    ?.map(value => ({ value, sort: Math.random() }))
                    .sort((a, b) => a.sort - b.sort)
                    .map(({ value }) => value)

                setFinalQuotes2(shuffledQuotes)

        }
    
      }


    // Creating a list of the themes' text values
    const options = themes?.map(theme => {
        return (
            <option
                key={theme.id}
                value={theme.id}

            > {theme.themeText}</option >
        )
    })

    let themeSelector = null
    if (!isRandomChecked) {
        themeSelector = (
        <>
            <p>Choose a Theme</p>
            <Form.Select id="theme"
                name="theme"
                className="form__select"
                value={theme}
                onChange={onThemeChanged}
            >
                {options}
            </Form.Select>
            <br /><br />
        </>
        )
    }

    let settingsDetail = null 
    let essayDetail = null
    if (!isStarted) {
        settingsDetail = (
            <div name="settingUp">
            <Alert className="purpleAlert" variant="warning">
            <p className="paragraphs">When you click start, you will be presented with 4 statements on a common theme.</p>
            <p className="paragraphs">You are expected to write a response to this theme in the writing area provided, and it is recommended that you spend 30 minutes completing the task.</p>
            <p className="paragraphs">Spellcheck, copying and pasting are disabled for this practice, as they will be in the real exam.</p>
            <Accordion defaultActiveKey="1">
      <Accordion.Item eventKey="0">
        <Accordion.Header>More Information on Section 2</Accordion.Header>
        <Accordion.Body>
            <i>The following information is adapted directly from ACER's GAMSAT Information Booklet</i>
            <hr></hr>
            The Written Communication section tests a test taker’s ability to generate and develop ideas in writing. It involves two (2) 30-minute writing tasks. Each task offers four statements on a common theme. The first task deals with socio-cultural issues and the second task deals with more personal and social issues. In selecting topics for the writing tasks every effort is made to minimise factors that might disadvantage test takers from non-English speaking backgrounds.
            <br /><br />
            Written Communication is assessed on two broad criteria:
            the quality of the thinking about a topic and the control and use of language demonstrated in the articulation of that thinking. Assessment focuses on the way in which ideas are integrated into a thoughtful response to the task. Control of language (grammatical structure and
            expression) is an integral component of a good piece of writing but it is not assessed in isolation. It is assessed insofar as it contributes to the overall effectiveness of the response to the task. Test takers are not assessed on the ‘correctness’ of the ideas or attitudes they display. Preprepared responses and responses that do not relate to the topic will receive a low score.
            <hr/>


            In scoring Section II, the following features of writing are considered:
            <p></p>

            <b>Thought and Content</b> (the quality of what is said)
            <ul>
                <li>
                    What is made of and developed from the task
                </li>
                <li>
                    The kinds of thoughts offered in response to the task
                </li>
            </ul>

            <b>Organisation and Expression</b> (the quality of the structure developed and language used)
            <ul>
                <li>
                    The shape and form of the piece
                </li>
                <li>
                    The effectiveness and fluency of the language
                </li>
            </ul>
            Each response is scored independently three times. All Section II Written Communication responses will undergo plagiarism checks as part of the marking process, this includes a plagiarism check against previously submitted GAMSAT responses.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
            </Alert>
            {isPro &&
            <div>

             <Form>
                <div key={`inline-radio`} className="mb-3">
                    How Many Essays do you want to write?
                    <br /> 
                    <Form.Check
                    inline
                    label="1"
                    name="group1"
                    type="radio"
                    id={`inline-radio-1`}
                    value="1"
                    onChange={handleEssayCountChange}
                    />
                    <Form.Check
                    inline
                    label="2"
                    name="group1"
                    type="radio"
                    id={`inline-radio-2`}
                    value="2"
                    onChange={handleEssayCountChange}
                    />
                
                </div>
            </Form>

            {essayCount === 1 ?
            <>
            <label htmlFor="randomCheckbox">Give me a random theme </label><span> </span>
            <input
              className="checkbox-lg"
              type="checkbox"
              id="randomCheckbox"
              name="randomCheckbox"
              value={isRandomChecked}
              checked={isRandomChecked}
              onChange={handleOnChangeRandom}
            />
            </>
                :
            <label>When writing 2 essays, two tasks with separate random themes are provided.</label>
    }
            <br /><br />
          </div>
    }    
    {themeSelector}

    <>
            <label htmlFor="randomCheckbox">Start Timer Immediately </label><span> </span>
            <input
              className="checkbox-lg"
              type="checkbox"
              id="randomCheckbox"
              name="randomCheckbox"
              value={immediateStartTimer}
              checked={immediateStartTimer}
              onChange={handleOnChangeImmediateStart}
            />
            </>
            <br /><br />

            <Button className="custom-btn" variant="outline-light" onClick={handleOnStarted}><span className="custom-button-class">Start Essay Practice</span></Button>
            <hr/>
            {!isPro && 
            <Alert variant="danger">
                <Alert.Heading>Make the Most of Your Gamsat Preparation</Alert.Heading>
                <hr />
                <p>Please note, you are currently using the Free Essay Practice Settings, which are great but limit you to only 10 themes to write about, and only one essay at a time.</p>
                <p>By upgrading to Pro, you will have access to over 50 themes, ranging from simple to complex topics. Additionally, you can choose to write either 1 or 2 essays at once, the latter being <b>an exact representation of what exam conditions are like</b>!</p>
                    
                <p>What's more, Pro access gives you the option to choose themes from a list before starting Essay Practice.</p>
                <Link to="/dash/payments/payment"><Button className="custom-btn" variant="success">Upgrade to Pro Now</Button></Link>
            </Alert>
            }
            </div>
        )
    } else {
        
        essayDetail = (  
        <>


        {essayCount === 2 ?
        <Tabs
            defaultActiveKey="A"
            className="mb-3"
            onSelect={(k) => setActiveTaskTab(k)}
        >
            <Tab
            eventKey="A" title="Task A"
            >
            <NewEssayForm theme = {theme} quotes = {finalQuotes} />
            </Tab>
            <Tab
            eventKey="B" title="Task B"
            >
            <NewEssayForm theme = {theme2} quotes = {finalQuotes2} />
            </Tab>
        </Tabs>
        :
        <NewEssayForm theme = {theme} quotes = {finalQuotes} />
        }
        </>
       )
    }

    const themeVisible = () => {
        let x = document.getElementById("themeText");
        if (x.style.display === "none") {
          x.style.display = "inline";
          setShowOrHide("Theme:")
        } else {
          x.style.display = "none";
          setShowOrHide("Reveal Theme")
        }
    }


    //quotes detail includes both the quotes and the timer
    let quotesDetail = null
    if (finalQuotes?.length) {
        quotesDetail = (
            <>
            <Card>
            <Accordion defaultActiveKey="">
        <Accordion.Item eventKey="0">
            {essayCount === 2 && <Accordion.Header><b>{activeTaskTab === "A" ? "Task A " : "Task B "}Comments</b></Accordion.Header>}
            
            {essayCount === 1 && <Accordion.Header><b> Task Comments</b></Accordion.Header>}
            <Accordion.Body>
                <i><p>
                    Consider the following comments and develop a piece of writing in response to one or more of them
                </p>
                <p>
                    Your writing will be judged on the quality of your response to the theme, how well you organise and present your point of view, and how effectively you express yourself.
                </p>
                </i>
            </Accordion.Body>
        </Accordion.Item> 
        </Accordion>
                        {activeTaskTab=== "A" &&
                <Card.Body>
                    <Card.Text>
                            {finalQuotes[0].quoteText} - <i> {finalQuotes[0].author} </i>
                    </Card.Text>         
                    <Card.Text>
                            {finalQuotes[1].quoteText} - <i> {finalQuotes[1].author} </i>             
                    </Card.Text>
                    <Card.Text>
                            {finalQuotes[2].quoteText} - <i> {finalQuotes[2].author} </i>             
                    </Card.Text>
                    <Card.Text>
                            {finalQuotes[3].quoteText} - <i> {finalQuotes[3].author} </i>             
                    </Card.Text>
                    <Button variant="outline-dark" onClick={themeVisible}>{showOrHide} <span style={{display: 'none'}} id="themeText"><i> {theme.themeText}</i></span></Button> 
                 </Card.Body>
                        }
                        {activeTaskTab=== "B" &&
                <Card.Body>
                    <Card.Text>
                            {finalQuotes2[0].quoteText} - <i> {finalQuotes2[0].author} </i>
                    </Card.Text>         
                    <Card.Text>
                            {finalQuotes2[1].quoteText} - <i> {finalQuotes2[1].author} </i>             
                    </Card.Text>
                    <Card.Text>
                            {finalQuotes2[2].quoteText} - <i> {finalQuotes2[2].author} </i>             
                    </Card.Text>
                    <Card.Text>
                            {finalQuotes2[3].quoteText} - <i> {finalQuotes2[3].author} </i>             
                    </Card.Text>
                    <Button variant="outline-dark" onClick={themeVisible}>{showOrHide} <span style={{display: 'none'}} id="themeText"><i> {theme2.themeText}</i></span></Button> 
                 </Card.Body>
                        }
                
            </Card>
            <br />
            <Timer essayCount={essayCount} immediateStartTimer={immediateStartTimer}/>
            </>
        )
    }

    // The final content rendered. Varies depending on whether the user is before
    // or after pressing the start button.
    const content = (
        <>
        <Container>
        <br/>
        {settingsDetail}
        <Container>
            <Row>
                <Col sm={8}>
                    {essayDetail}
                </Col>
                <Col >{quotesDetail}</Col>
            </Row>

        </Container>
        </Container>
        </>
    )
  return content
}
export default EssaySettingsForm