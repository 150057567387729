import React, { useState, useEffect } from "react";
import { Button, Card, Modal } from "react-bootstrap";

function Timer( {essayCount, immediateStartTimer}) {
const [timeRemaining, setTimeRemaining] = useState(essayCount ? essayCount * 32.5 * 60 : 32.5 * 60); // 30 minutes in seconds
const [isRunning, setIsRunning] = useState(false);
const [buttonText, setButtonText] = useState('Start')

const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);


useEffect(() => {
  if (immediateStartTimer) {
    startTimer()
  }
},[immediateStartTimer])


useEffect(() => {

let intervalId;
if (isRunning) {
intervalId = setInterval(() => {
setTimeRemaining(timeRemaining - 1);
}, 1000);
} else if (!isRunning && timeRemaining !== 0) {
clearInterval(intervalId);
}

if (timeRemaining === 0){
    pauseTimer()
    handleShow()
    // window.alert("Times Up. To reflect exam conditions, it is recommended you stop writing. But hey, that's up to you - I'm just a computer.")
}

return () => clearInterval(intervalId);
}, [isRunning, timeRemaining, essayCount]);

const handleTimerStartPause = () => {
  if (isRunning) {
    pauseTimer()
  } else {
    startTimer()
  }
}

const startTimer = () => {
setIsRunning(true);
setButtonText('Pause')
};

const pauseTimer = () => {
setIsRunning(false);
setButtonText('Resume')
};

const resetTimer = () => {
setTimeRemaining(32.5 * 60);
setIsRunning(false);
setButtonText('Start')
};

const minutes = Math.floor(timeRemaining / 60);
const seconds = timeRemaining % 60;

return (
<>
<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Time's Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            Woohoo, you've completed an essay!
            <br /><br/>
            To abide by exam conditions, it's recommended that you stop typing and save your work.     
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    <Card>
        <Card.Header>
            Timer
        </Card.Header>
        <Card.Body>
            <Card.Text>
                <p>Time Remaining: {minutes}:{seconds < 10 ? `0${seconds}`: seconds} </p>                
            </Card.Text>
            <Card.Text>
            {/* <Button className="custom-btn" variant="outline-light" onClick={handleTimerStartPause}><span className="custom-button-class">{buttonText}</span></Button> <Button variant="secondary" onClick={pauseTimer}>Pause</Button> <Button variant="secondary" onClick={resetTimer}>Reset</Button> */}
            <Button className="custom-btn" variant="outline-light" onClick={handleTimerStartPause}><span className="custom-button-class">{buttonText}</span></Button> <Button variant="secondary" onClick={resetTimer}>Reset</Button>
            </Card.Text>
        </Card.Body>
    </Card>
    </>
);
}

export default Timer;