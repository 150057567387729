import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import EssayCorrectCheckoutForm from "./EssayCorrectCheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Container, Alert } from "react-bootstrap";

function EssayCorrectPayment({essay}) {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
 
 // testing urls
  // useEffect(() => {
  //   fetch("http://localhost:3500/config").then(async (r) => {
  //     const { publishableKey } = await r.json();
  //     setStripePromise(loadStripe(publishableKey));
  //   });
  // }, []);

  // useEffect(() => {
  //   fetch("http://localhost:3500/create-payment-intent", {
  //     method: "POST",
  //     body: JSON.stringify({}),
  //   }).then(async (result) => {
  //     var { clientSecret } = await result.json();
  //     setClientSecret(clientSecret);
  //   });
  // }, []);

  useEffect(() => {
    fetch("https://yakkagamsat-api.onrender.com/config").then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    fetch("https://yakkagamsat-api.onrender.com/create-payment-intent", {
      method: "POST",
      body: JSON.stringify({}),
    }).then(async (result) => {
      var { clientSecret } = await result.json();
      setClientSecret(clientSecret);
    });
  }, []);

  return (
    <>
    <Container>
    <br />
    <Alert variant="success">
      <h4>Get Objective Feedback from Professionals.</h4>
      
        Want to get an insight into how your writing is coming along? YakkaGamsat's bespoke essay feedback service uses a standardised set of assessment criteria for each submission we recieve. Once the payment below is successful, your essay will automatically be placed in our correction queue. We will then work to deliver your feeback as quickly as possible, and we will send you an email when it's ready. 
        </Alert>
      <h6>You will be charged $29.99 AUD for the correction of this essay.</h6>
      {clientSecret && stripePromise && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
          <EssayCorrectCheckoutForm essay={essay} clientSecret={clientSecret}/>
        </Elements>
      )}
      </Container>
    </>
  );
}

export default EssayCorrectPayment;