import { useState, useEffect } from "react"
import { useUpdateQuoteMutation, useDeleteQuoteMutation } from "./quotesApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import useAuth from "../../hooks/useAuth"
const EditQuoteForm = ({ quote, themes }) => {


    const [updateQuote, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateQuoteMutation()

    const [deleteQuote, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteQuoteMutation()

    const navigate = useNavigate()

    const {isSuperuser} = useAuth()
  
    if (!isSuperuser) navigate('/dash')
    

    const [quoteText, setQuoteText] = useState(quote.quoteText)
    const [author, setAuthor] = useState(quote.author)
    const [theme, setTheme] = useState(quote.theme)
    

    useEffect(() => {
        console.log(isSuccess)
        if (isSuccess || isDelSuccess) {
            setQuoteText('')
            setAuthor('')
            navigate('/dash/quotes')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onQuoteTextChanged = e => setQuoteText(e.target.value)
    const onAuthorChanged = e => setAuthor(e.target.value)
    const onThemeChanged = e => setTheme(e.target.value)


    const onSaveQuoteClicked = async (e) => {
        if (author) {
            await updateQuote({ id: quote.id, quoteText, author, theme })
        } else {
            await updateQuote({ id: quote.id, quoteText })
        }
    }

    const onDeleteQuoteClicked = async () => {
        await deleteQuote({ id: quote.id })
    }

    const options = themes.map(theme => {
        return (
            <option
                key={theme.id}
                value={theme.id}

            > {theme.themeText}</option >
        )
    })

    let canSave
    if (quoteText) {
        canSave = [quoteText, author, theme].every(Boolean) && !isLoading
    } 

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validQuoteClass = !quoteText ? 'form__input--incomplete' : ''
    
    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    const content = (
        <>
            <p className={errClass}>{errContent}</p>

            <form className="form" onSubmit={e => e.preventDefault()}>
                <div className="form__title-row">
                    <h2>Edit Quote</h2>
                    <div className="form__action-buttons">
                        <button
                            className="icon-button"
                            title="Save"
                            onClick={onSaveQuoteClicked}
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} />
                        </button>
                        <button
                            className="icon-button"
                            title="Delete"
                            onClick={onDeleteQuoteClicked}
                        >
                            <FontAwesomeIcon icon={faTrashCan} />
                        </button>
                    </div>
                </div>
                <label className="form__label" htmlFor="quoteText">
                    Quote: </label>
                <input
                    className={`form__input ${validQuoteClass}`}
                    id="quoteText"
                    text="quoteText"
                    type="text"
                    autoComplete="off"
                    value={quoteText}
                    onChange={onQuoteTextChanged}
                />
                
            
                <label className="form__label" htmlFor="author">
                    Author: </label>
                <input
                    className={`form__input`}
                    id="author"
                    text="author"
                    type="author"
                    value={author}
                    onChange={onAuthorChanged}
                />

                <label className="form__label form__checkbox-container" htmlFor="theme">
                    Theme:</label>
                <select
                    id="theme"
                    name="theme"
                    className="form__select"
                    value={theme}
                    onChange={onThemeChanged}
                >
                    {options}
                </select>

            </form>
        </>
    )

    return content
}
export default EditQuoteForm