import React from 'react'
import { Container, Navbar, Nav } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const VerifiedUser = () => {
  return (
    <>
       <Navbar bg="light" variant="light" expand="lg">
                <Container >
                    {/* <Navbar.Brand href="/"> <img className="nav-image" src="./YGTextColor.png" alt="YG-logo"/></Navbar.Brand> */}
                    <Navbar.Brand href="/"> 
                    <img className="navlogo" src="./YGLogo.png" alt=""/>
                    <span className="navBrandText">
                        YakkaGamsat
                    </span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse>
                    <Nav className="me-auto">
                        
                    </Nav>
                    </Navbar.Collapse>
                    </Container>
                </Navbar>
    <Container>
      <br />
        <h4 className='h4-headers'>You're all set! 🙌</h4>
        <p>Your account has been verified.</p>
        You can now <Link className='smallLinks' to="/login">Log in</Link> to access YakkaGamsat resources.

    </Container>
    </>
  )
}

export default VerifiedUser

