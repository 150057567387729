import {React, useState } from 'react'
import { Form, Button, Alert } from 'react-bootstrap'
import useAuth from '../../hooks/useAuth'
import { useAddNewFeedbackMutation } from './feedbackApiSlice'
const Feedback = () => {

    const {email, username} = useAuth()

    const [addNewFeedback, {
        isSuccess,
    }] = useAddNewFeedbackMutation() 


    const [feedbackText, setFeedbackText] = useState()
    const [feedbackStatus, setFeedbackStatus] = useState()
    const onFeedbackChanged = e => setFeedbackText(e.target.value)

    const handleSubmit = async (e) => {
        setFeedbackStatus('Feedback successfully submitted, thank you!')
        
        if (feedbackText){
            await addNewFeedback({feedbackText, username, email})
            setFeedbackText('')
            if (isSuccess) {
            }
        }
        
    }

  return (
    
    <>
    <Alert className='purpleAlert' variant="light">
      <h6 className='h6-headers'>Let us know what you think!</h6>
    <p className='paragraphs'>We're constantly looking to improve service to our members. Whether there's something you love, something that's gone wrong, or if are there additional features you'd like us to deliver - we'd love to hear from you. Let us know below 👇 and we'll do our best to figure it out.</p>
    <Form onSubmit={handleSubmit}>
            <Form.Control 
            as="textarea"
            placeholder="Give your two cents..."
            onChange={onFeedbackChanged}
            value={feedbackText}
            />
    {feedbackStatus}
    <hr />
    <Button className='custom-btn' onClick={handleSubmit} variant="outline-light">
      <span className='custom-button-css'>Submit</span>
    </Button>
    </Form>
            </Alert>

    </>
  )
}
 
export default Feedback