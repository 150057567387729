import React from 'react'
import { Badge, Card, Button, Col, Row, Container} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Feedback from '../features/feedback/Feedback'
import useAuth from '../hooks/useAuth'
const DashContent = () => {

    const {isPro} = useAuth()

  return (
    <Container>
    <h5>
    <Badge bg="dark">Your Dashboard</Badge>
    </h5>
<Row>
            
            <h6 className='h6-headers'>Section 2, Fully Covered</h6>
            <p className='paragraphs'>
                Practice writing. Get Professional Feedback. Share with others. All in One Place.
            </p>
            <Col sm={3}>
            <Card bg="light">
                 <Card.Img variant="top" className="dashboard-card-img" src="./img/writing.jpg" />
                    <Card.Body>
                        <Card.Title>Essay Practice</Card.Title>
                        <Card.Text>
                            Prepare for Section 2 using our bespoke exam simulation software. Use this daily to improve your writing performance.
                        </Card.Text>
                        <Link to="/dash/essays/setup">
                            <Button className='custom-btn' variant="outline-light">
                               <span className='custom-button-class'>Practice Now</span>
                            </Button>
                        </Link>
                    </Card.Body>
            </Card>
            <br />
            </Col>
            
            <Col sm={3}>
            <Card bg="light">
            <Card.Img variant="top" className="dashboard-card-img" src="./img/essays.jpg" />
                    <Card.Body>
                        <Card.Title>Your Essays</Card.Title>
                        <Card.Text>
                            A collection of the essays you have completed in Essay Practice. Here you can choose to publish these to the YakkaGamsat community.
                        </Card.Text>
                        <Link to="/dash/essays">
                            <Button className='custom-btn' variant="outline-light">
                            <span className='custom-button-class'>Go to Your Essays</span>
                            </Button>
                        </Link>
                    </Card.Body>
            </Card>
            <br />
            </Col>
            
            
            <Col sm={3}>
            <Card bg="light">
            <Card.Img variant="top" className="dashboard-card-img" src="./img/sharing.jpg" />
                    <Card.Body>
                        <Card.Title>Published Essays</Card.Title>
                        <Card.Text>
                            View essays that members have shared with the YakkaGamsat community. Join the discussion by giving kudos and feeback to other Yakkas!
                        </Card.Text>
                        <Link to="/dash/essays/published">
                            <Button className='custom-btn' variant="outline-light">
                            <span className='custom-button-class'>View Published Essays</span>
                            </Button>
                        </Link>
                    </Card.Body>
            </Card>
            <br />
            </Col>

            <Col sm={3}>
            <Card bg="light">
                <Card.Img variant="top" className="dashboard-card-img" src="./img/fountainpen.jpg" />
                    <Card.Body>
                        <Card.Title>Pro Essays <small><Badge bg="success">Pro</Badge></small></Card.Title>
                        <Card.Text>
                            Learn from the best by reviewing essays of candidates scoring in the 95th+ percentile.
                        </Card.Text>
                        
                        {isPro && 
                        <Link to="/dash/essays/pro">
                            <Button className='custom-btn' variant="outline-light">
                            <span className='custom-button-class'>View Pro Essays</span>
                            </Button>
                        </Link>
                        }
                        {!isPro && 
                        <Link to="/dash/payments/payment">
                            <Button className='custom-btn' variant="outline-light">
                            <span className='custom-button-class'>Get Pro Access Now</span>
                            </Button>
                        </Link>
                        }
                    </Card.Body>
            </Card>
            <br />
            </Col>
            </Row>

            {/* <Alert variant="secondary-outline-light"> */}
            <Row>
            <h6 className='h6-headers'>Preparation Strategies</h6> 
            <p className='paragraphs'>We keep it simple by offering straightforward, high-yield strategies for GAMSAT preparation.</p>
            <Col sm={3}>
            <Card bg="light">
                 
                    <Card.Body>
                        <Card.Title>Kickstart Your Preparation <small><Badge bg="success">Pro</Badge></small></Card.Title>
                        <Card.Text>
                            Hit the ground running with our digestible advice to those starting their Gamsat Journey.
                        </Card.Text>
                       {isPro &&<Link to="/dash/strategy/startingoff">
                            <Button className='custom-btn' variant="outline-light">
                            <span className='custom-button-class'>Let's Go</span>
                            </Button>
                        </Link>
                        }
                        {!isPro && 
                        <Link to="/dash/payments/payment">
                            <Button className='custom-btn' variant="outline-light">
                            <span className='custom-button-class'>Get Pro Access Now</span>
                            </Button>
                        </Link>
                        }
                    </Card.Body>
            </Card>
            <br />
            </Col>

            <Col sm={3}>
            <Card bg="light">
                 
                    <Card.Body>                
                        <Card.Title>Section 1 <small><Badge bg="success">Pro</Badge></small></Card.Title>
                        <Card.Text>
                            Getting to grips with what the Humanities and Social Sciences are all about.
                        </Card.Text>
                        {isPro &&<Link to="/dash/strategy/section1">
                            <Button className='custom-btn' variant="outline-light">
                            <span className='custom-button-class'>Section 1</span>
                            </Button>
                        </Link>
                        }
                        {!isPro && 
                        <Link to="/dash/payments/payment">
                            <Button className='custom-btn' variant="outline-light">
                            <span className='custom-button-class'>Get Pro Access Now</span>
                            </Button>
                        </Link>
                        }
                    </Card.Body>
            </Card>
            <br />
            </Col>
            <Col sm={3}>
            <Card bg="light">
                 
                    <Card.Body>
                        <Card.Title>Section 2 <small><Badge bg="success">Pro</Badge></small></Card.Title>
                        <Card.Text>
                            A masterclass strategy to ensure you will write excellent responses to the prompts on exam day. 
                        </Card.Text>
                        {isPro &&<Link to="/dash/strategy/section2">
                            <Button className='custom-btn' variant="outline-light">
                            <span className='custom-button-class'>Master Section 2</span>
                            </Button>
                        </Link>
                        }
                        {!isPro && 
                        <Link to="/dash/payments/payment">
                            <Button className='custom-btn' variant="outline-light">
                            <span className='custom-button-class'>Get Pro Access Now</span>
                            </Button>
                        </Link>
                        }
                    </Card.Body>
            </Card>
            <br />
            </Col>
            <Col sm={3}>
            <Card bg="light">
                 
                    <Card.Body>
                        <Card.Title>Section 3 <small><Badge bg="secondary">Coming Soon</Badge></small></Card.Title>
                        <Card.Text>
                                The dos and don'ts of preparing for the final section of the exam.
                        </Card.Text>
                        
                            <Button className='custom-btn' disabled variant="outline-light">
                                Coming Soon
                            </Button>
                    
                    </Card.Body>
            </Card>
            </Col>
            </Row>
            <hr />
            <Feedback />
            </Container>
  )
}

export default DashContent