
import { useGetCommentsQuery, useDeleteCommentMutation } from "./commentsApiSlice"
import { memo } from "react"
import { Button } from "react-bootstrap"
import useAuth from "../../hooks/useAuth"
import { useGetUsersQuery } from "../users/usersApiSlice"

export const Comment = ({ commentId }) => {

    const {userId} = useAuth()

    const { comment } = useGetCommentsQuery("commentList", {
        selectFromResult: ({ data }) => ({
            comment: data?.entities[commentId]
        })
    })

    const {user} = useGetUsersQuery("userList", {
        selectFromResult:({ data }) => ({
            user: data?.entities[comment?.user]
        })
    })

    const [deleteComment, {
        isSuccess,
     
    }] = useDeleteCommentMutation()

    if (isSuccess) {
        console.log("delete comment success")
    }


    if (comment) {
        const handleEdit = () => {
            deleteComment({"id": comment.id})
        }
    
        let date = new Date(comment.createdAt)
        date = date.toDateString().split(' ').slice(1).join(' ')


        return (
            <>
            <tr>
                <td>
                    <i><b>{user.username}</b></i>
                    <br />
                    {comment.commentText}
                    <br />
                    <small>on {date}</small>
                <br />
                {comment.user === userId && <Button
                        variant="outline-dark"
                        onClick={handleEdit}
                    >
                        Delete Comment                    
                    </Button>
                    }
                </td>
                
            </tr>

    </> 
        )


    } else return null

}
const memoizedComment = memo(Comment)
export default memoizedComment