import {React, useState, useEffect} from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Container, Form } from 'react-bootstrap'
import Question from './Question'
import { useAddNewUnitMutation } from './unitsApiSlice'

const Unit = () => {

  const [addNewUnit, {
    isLoading,
    isSuccess,
    isError,
    error
}] = useAddNewUnitMutation()

  const [unitId, setUnitId] = useState('')
  const [unitSection, setUnitSection] = useState('')
  const [unitCategory, setUnitCategory] = useState('')
  const [unitText, setUnitText] = useState()
  const [unitContentMessage, setUnitContentMessage] = useState('')
  const [saveUnitDisabled, setSaveUnitDisabled] = useState(false)
  const navigate = useNavigate()

  const handleUnitTextChange = e => {
    setUnitText(e.target.value)
  }

  const handleUnitSectionChange = e => {
    setUnitSection(e.target.value)
  }

  const handleUnitCategoryChange = e => {
    setUnitCategory(e.target.value)
  }

  // here we are just adding a question component so 
  // that we can have n number of questions for any given unit


  // here we are adding the unit to the database, including questions
  const handleSaveUnit = async () => {
    // currently we are successfully adding a unit with the code here
    // WHAT WE WANT
    // is to add a unit with related questions 

  
    // we're getting the response from addNewUnit 
    const {data} = await addNewUnit({"text": unitText, "section": unitSection, "category": unitCategory})

    if (isError) {
      console.log("Error adding unit" + error)
    }

    // if there is data, i.e. a new unit is added, 
    // we get the id from this, and then we will add the questions to this unit 
    if (data) {
      console.log(data)
      setUnitId(data.unit._id)
      console.log(unitId)
      // for questions in question array

      // add question to Question model

      // add answers to Answer model

      // add answerId to Question model

      // add questionId to Unit

    }

    if (isLoading) {
      console.log("loading unit add")
    }

  }

  useEffect(() => {
    
    if (isSuccess) {
      setUnitContentMessage('Unit Content Saved. Now Add Questions for the unit.')
      setSaveUnitDisabled(true)
    }

}, [isSuccess, navigate])



    // here will be where we setup a unit, which includes a passage
    // and a set of questions relating to it.
    // This is for admins to make the question sets. 
  return (
    <>
    <Container>
    <h6 className='h6-headers'>New Unit</h6>
    <Form className="form" onSubmit={e => e.preventDefault()}>
    <Form.Select
    onChange={handleUnitSectionChange}
    value={unitSection}
    >
    <option>Choose Section</option>
      <option value="1">Section One</option>
      <option value="3">Section Three</option>
    </Form.Select>
    <br />
    <Form.Select
    onChange={handleUnitCategoryChange}
    value={unitCategory}
    >
    <option>Choose Category</option>
      <option value="Fictional">Fictional Passage</option>
      <option value="News">News Article</option>
      <option value="Poetry">Poetry</option>
      <option value="Cartoon">Cartoon</option>
      <option value="Diagram">Diagram</option>
    </Form.Select>
    <br />
      <Form.Control
        as="textarea"
        onChange={handleUnitTextChange}
        placeholder='Passage relating to questions'
        rows={12}
        value={unitText}
      />
      <br />

{ !saveUnitDisabled &&
    <Button disabled={saveUnitDisabled} onClick={handleSaveUnit} variant='warning'>Save Unit Content</Button>
}
    <p>{unitContentMessage}</p>
      </Form>

    <hr />
      <Question unit={unitId} />

    <br />
    </Container>
    </>
  )
}

export default Unit