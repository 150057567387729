import { useNavigate, useLocation, Link } from "react-router-dom"
import { useEffect } from "react"
import { useSendLogoutMutation } from "../features/auth/authApiSlice"
import useAuth from '../hooks/useAuth'
import { Nav, Navbar, Button, Container} from "react-bootstrap"


const DASH_REGEX = /^\/dash(\/)?$/
const THEMES_REGEX = /^\/dash\/themes(\/)?$/
const USERS_REGEX = /^\/dash\/users(\/)?$/  
const ESSAYS_REGEX = /^\/dash\/essays(\/)?$/  
const QUOTES_REGEX = /^\/dash\/quotes(\/)?$/  

const DashHeader = () => {
  const { isSuperuser, userId } = useAuth()
  const navigate =useNavigate()
  const { pathname } = useLocation()

  const [sendLogout, {
      isLoading,
      isSuccess, 
      isError,
      error
  }] = useSendLogoutMutation()

  useEffect(() => {
      if (isSuccess) navigate('/')
  }, [isSuccess, navigate])


  const onNewThemeClicked = () => navigate('/dash/themes/new')
  const onNewUserClicked = () => navigate('/dash/users/new')
  const onNewEssayClicked = () => navigate('/dash/essays/setup')
  const onNewQuoteClicked = () => navigate('/dash/quotes/new')
  const onGoHomeClicked = () => navigate('/dash')
  const onGoToMyAccount = () => navigate(`/dash/users/${userId}`)

  if (isLoading) return <p>Logging Out...</p>

  if (isError) return <p>Error: {error.data?.message}</p>

      let dashClass = null 
    if (!DASH_REGEX.test(pathname) && !THEMES_REGEX.test(pathname) && !USERS_REGEX.test(pathname) && !ESSAYS_REGEX.test(pathname) && !QUOTES_REGEX.test(pathname)) {
        dashClass = "dash-header__container--small"
    }

    let newThemeButton = null 
    if (isSuperuser) {
        if (THEMES_REGEX.test(pathname)) {
            newThemeButton = (
                <Button
                    variant="light" 
                    title="New Theme"
                    onClick={onNewThemeClicked}
                >
                    New Theme
                </Button>
            )
        }
    }

    let newUserButton = null 
    if (isSuperuser) {
        if (USERS_REGEX.test(pathname)) {
            newUserButton = (
                <Button
                    variant="light"
                    title="New User"
                    onClick={onNewUserClicked}
                >
                    New User
                </Button>
            )
        }
    }

    let newEssayButton = null 
    if (ESSAYS_REGEX.test(pathname)) {
        newEssayButton = (
            <Button
                variant="light"
                title="New Essay"
                onClick={onNewEssayClicked}
            >
                Essay Practice
            </Button>
        )
    }

    let newQuoteButton = null 
    if (isSuperuser){
        if (QUOTES_REGEX.test(pathname)) {
            newQuoteButton = (
                <Button
                    variant="light"
                    title="New Quote"
                    onClick={onNewQuoteClicked}
                >
                    New Quote
                </Button>
            )
        }
    }
    const logoutButton = (
            

      <Button
        className="custom-btn" variant="outline-light"
        title="Logout"
        onClick={sendLogout}
      >
        Log Out
      </Button>
  )

//   const errClass = isError ? "errmsg" : "offscreen"

  let buttonContent 
  if (isLoading) {
      buttonContent = <p> Logging Out...</p>
  } else {
      buttonContent = (
          <>
              {newThemeButton} 
              {newUserButton} 
              {newEssayButton} 
              {newQuoteButton} 
              {logoutButton}
          </>
      )
  }

  const content =  (
    <>

<Navbar bg="light" variant="light" expand="lg">            
    <Container >
    <Link class="no-link-decoration" to="/dash">
    <Navbar.Brand> 
    <img className="navlogo" src="./YGLogo.png" alt=""/>
                    <span className="navBrandText">
                        YakkaGamsat
                    </span>
    </Navbar.Brand>
    </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className={dashClass}>
        <Nav className="me-auto">
        <Nav.Link onClick={onGoHomeClicked}>Home</Nav.Link>                    
        </Nav>
        <Nav.Link style={{paddingRight: '10px'}} onClick={onGoToMyAccount}><Button variant="outline-dark">Account</Button></Nav.Link>
            {buttonContent}
        </Navbar.Collapse>
    </Container>
</Navbar>


</>
  )
  return content
}

export default DashHeader