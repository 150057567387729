import NewEssayForm from './NewEssayForm'
import { useGetThemesQuery } from '../themes/themesApiSlice'
import { useGetQuotesQuery } from '../quotes/quotesApiSlice'

const NewEssay = () => {

    const { themes } = useGetThemesQuery("themesList", {
        selectFromResult: ({ data }) => ({
            themes: data?.ids.map(id => data?.entities[id])
        })
    })

    const { quotes } = useGetQuotesQuery("quotesList", {
        selectFromResult: ({ data }) => ({
            quotes: data?.ids.map(id => data?.entities[id])
        })
    })

  
    const content = <NewEssayForm themes={themes} quotes={quotes} />
  
    return content 
}

export default NewEssay