import { useState } from "react"
import { } from "./essaysApiSlice"
import {  } from "react-router-dom"
import {  Container, Row, Col, Alert, Card, Badge } from "react-bootstrap"
import CommentSection from "./CommentSection"
import Likes from "../likes/Likes"
import useAuth from "../../hooks/useAuth"
import CorrectEssay from "./CorrectEssay"
const ViewEssayForm = ({ essay, user }) => {
    const {isSuperuser} = useAuth()
    const [essayText] = useState(essay.essayText)
    const [username] = useState(user?.username)
    const { isPro, userScore } = essay 

    const content = (
        <>
            <Container>
            <Row>
            <Col sm={8}>
                <br />
                <Alert variant="warning">
                    Please note that you are viewing another person's piece of work. When writing feedback, please aim to be constructive. 
                </Alert>
            
                {isPro &&
                <Alert variant="success">
                    You are viewing a <Badge bg="success">Pro Essay</Badge>. These essays are from members who scored 80+ in section 2 of the GAMSAT. This does not necessarily mean that the content is flawless, as every effort is made to ensure that submissions have been written under exam conditions to accurately reflect an acheivable effort on exam day.  
                </Alert>
                }
                
                <p>Essay from user <b><i>{username}</i></b></p>
                
                
                <p className="wrappedParagraph noselect">{essayText}</p>
                
                {(isSuperuser && essay.correctionDue) && 
                    <CorrectEssay essay={essay} />
                }
                <br />
                {/* Likes Section */}
                <Likes essay = {essay} />
                <hr />
                <h6>Discussion <Badge>New</Badge></h6>
                <CommentSection essay = {essay} />


                <hr />
      
                </Col>
                <Col >

    <br />
            <Card>
                <Card.Header>
                    Info
                </Card.Header>
                <Card.Body>
                    <Card.Text>
                        <p>{essay.quotes[0]}</p>            
                    </Card.Text>
                    <Card.Text>
                        <p>{essay.quotes[1]}</p>
                    </Card.Text>
                    <Card.Text>
                        <p>{essay.quotes[2]}</p>
                    </Card.Text>
                    <Card.Text>
                        <p>{essay.quotes[3]}</p>
                    </Card.Text>
                    <p>The associated theme for this task was <b>{essay.theme}</b></p>

                    {isPro && <>
                    <hr />
                    <Badge bg="success">Gamsat Score</Badge>
                    <br />
                    The user who posted this essay scored <b> {userScore} </b>in section 2 of the GAMSAT.
                    <hr />
                    <Badge bg="secondary">Want to be listed among the Pros?</Badge>
                    <br />
                    Use the Essay Practice section of YakkaGamsat in the lead up to exam day. If you receive a score of 80+ in the exam, with your permission we will publish some of your work for future candidates to aspire to.
                    </>
                    }

                 </Card.Body>
                
            </Card>
                </Col>
            </Row>
        </Container>
</>
    )

    return content
}
export default ViewEssayForm