import { Link } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import { Container, Button, Row, Alert, Col } from "react-bootstrap"
import DashContent from "../../components/DashContent"

const Welcome = () => {
  
    const { username, isSuperuser, isPro } = useAuth()

    const content = (
        <>
        <Container>
            <Row className="justify-content-md-center"> 
            <Col>
            <br></br>
            {isPro && <><h4 className="h4-headers">Hello again, {username} 👋</h4></>}
            {(!isPro) &&
            <>
            <h4>What's up, {username}?</h4>
            <Alert className="purpleAlert" variant="warning">
                <p className="paragraphs">We hope you're enjoying YakkaGamsat's Free Features. To bolster your preparation even further, switch to Pro and unlock access to the full suite of Section 2 practice topics and preparation advice.</p>
                <Link to="payments/payment">
                <Button className="custom-btn" variant="outline-light">
                <span className="custom-button-class">Go Pro</span>
                </Button>
                </Link>
            </Alert>    
            </>
            }
            <hr />


            {(isSuperuser) && 
            <Alert className="purpleAlert" variant="outline-light">
                <h6>Admin</h6>
            <Link to="/dash/users">
                <Button className="custom-btn" variant="outline-light">
                <span className="custom-button-css">Users</span>
                </Button>
            </Link>
            <span> </span>
            <Link to="/dash/quotes">
                <Button className="custom-btn" variant="outline-light">
                <span className="custom-button-css">Quotes</span>
                </Button>
            </Link>
            <span> </span>
            <Link to="/dash/themes">
                <Button className="custom-btn" variant="outline-light">
                <span className="custom-button-css">Themes</span>
                </Button>
            </Link>
            <span> </span>
            <Link to="/dash/essays/corrections">
                <Button className="custom-btn" variant="outline-light">
                <span className="custom-button-css">Essay Corrections</span>
                </Button>
            </Link>
            </Alert>
            }
            </Col>
            </Row>

        </Container>

        <DashContent />
     </>
    )

    return content
    
}

export default Welcome