import { useGetUsersQuery } from "./usersApiSlice"
import User from "./User"
import { Container, Spinner } from "react-bootstrap"
import useAuth from "../../hooks/useAuth"
import { useNavigate } from "react-router-dom"

const UsersList = () => {
  
const navigate = useNavigate()
const {isSuperuser} = useAuth()

if (!isSuperuser) navigate('/dash')

  const {
    data: users,
    isLoading,
    isSuccess,
    isError,
    error
  } = useGetUsersQuery()

  let content 

  if (isLoading) content = 
  <div className="spinner">
    <Spinner animation="border" />
  </div>
  
  if(isError) {
    content = <p className="errmsg">{error?.data?.message}</p>
  }

  if (isSuccess) {
    const { ids } = users 

    const tableContent = ids?.length 
    ? ids.map(userId => <User key={userId} userId={userId} />)
    : null 

    content = (
      <>
      <Container>
      <table className="table table--users">
                <thead>
                    <tr>
                        <th>Username</th>
                        <th>Email</th>
                        <th>Sign Up Date</th>
                        <th>Verification Status</th>
                        <th>Roles</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {tableContent}
                </tbody>
            </table>
      </Container>
      </>
    )
  }
  return content 
}
export default UsersList