import { useState, useEffect } from "react"
import { useUpdateThemeMutation, useDeleteThemeMutation } from "./themesApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave, faTrashCan } from "@fortawesome/free-solid-svg-icons"
import useAuth from "../../hooks/useAuth"

const EditThemeForm = ({ theme }) => {


    const {isSuperuser } = useAuth()

    const [updateTheme, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateThemeMutation()

    const [deleteTheme, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteThemeMutation()

    const navigate = useNavigate()

    if (!isSuperuser) navigate('/dash')

    const [themeText, setThemeText] = useState(theme.themeText)
    
    const [description, setDescription] = useState(theme.description)
    

    useEffect(() => {
        console.log("Checking Delete Success: " + isDelSuccess)
        if (isSuccess || isDelSuccess) {
            setThemeText('')
            setDescription('')
            navigate('/dash/themes')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onThemeTextChanged = e => setThemeText(e.target.value)
    const onDescriptionChanged = e => setDescription(e.target.value)


    const onSaveThemeClicked = async (e) => {
        if (description) {
            await updateTheme({ id: theme.id, themeText, description })
        } else {
            await updateTheme({ id: theme.id, themeText })
        }
    }

    const onDeleteThemeClicked = async () => {
        await deleteTheme({ id: theme.id })
    }

    let canSave
    if (themeText) {
        canSave = [themeText].every(Boolean) && !isLoading
    } 

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validThemeClass = !themeText ? 'form__input--incomplete' : ''
    
    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    const content = (
        <>
            <p className={errClass}>{errContent}</p>

            <form className="form" onSubmit={e => e.preventDefault()}>
                <div className="form__title-row">
                    <h2>Edit Theme</h2>
                    <div className="form__action-buttons">
                        <button
                            className="icon-button"
                            title="Save"
                            onClick={onSaveThemeClicked}
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} />
                        </button>
                        <button
                            className="icon-button"
                            title="Delete"
                            onClick={onDeleteThemeClicked}
                        >
                            <FontAwesomeIcon icon={faTrashCan} />
                        </button>
                    </div>
                </div>
                <label className="form__label" htmlFor="themeText">
                    Theme: </label>
                <input
                    className={`form__input ${validThemeClass}`}
                    id="themeText"
                    text="themeText"
                    type="text"
                    autoComplete="off"
                    value={themeText}
                    onChange={onThemeTextChanged}
                />
                <br />
                <br />
                <label className="form__label" htmlFor="description">
                    Description: </label>
                <input
                    className={`form__input`}
                    id="description"
                    text="description"
                    type="description"
                    value={description}
                    onChange={onDescriptionChanged}
                />
            </form>
        </>
    )

    return content
}
export default EditThemeForm