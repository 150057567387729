import { createSelector, createEntityAdapter } from "@reduxjs/toolkit"
import { apiSlice } from "../../app/api/apiSlice"

const answersAdapter = createEntityAdapter()
const initialState = answersAdapter.getInitialState()

export const answersApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getAnswers: builder.query({
            query: () => ({
                url: '/answers',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
            },
        }),
            transformResponse: responseData => {
                const loadedAnswers = responseData.map(answer => {
                    answer.id = answer._id
                    return answer
                });
                return answersAdapter.setAll(initialState, loadedAnswers)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Answer', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'Answer', id }))
                    ]
                } else return [{ type: 'Answer', id: 'LIST'}]
            }
        }),
        addNewAnswer: builder.mutation({
            query: initialAnswer => ({
                url: '/answers',
                method: 'POST',
                body: {
                    ...initialAnswer,
                }
            }),
            invalidatesTags: [
                { type: 'Answer', id: "LIST" }
            ]
        }),
        updateAnswer: builder.mutation({
            query: initialAnswer => ({
                url: '/answers',
                method: 'PATCH',
                body: {
                    ...initialAnswer,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Answer', id: arg.id }
            ]
        }),
        deleteAnswer: builder.mutation({
            query: ({ id }) => ({
                url: `/answers`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Answer', id: arg.id }
            ]
        }),
    })
})

export const {
    useGetAnswersQuery,
    useAddNewAnswerMutation,
    useUpdateAnswerMutation,
    useDeleteAnswerMutation,
} = answersApiSlice

export const selectAnswersResult = answersApiSlice.endpoints.getAnswers.select()

const selectAnswersData = createSelector(
    selectAnswersResult,
    answersResult => answersResult.data 
)

export const {
    selectAll: selectAllAnswers,
    selectById: selectAnswerById,
    selectIds: selectAnswerIds
} = answersAdapter.getSelectors(state => selectAnswersData(state) ?? initialState)