import { useState, useEffect } from "react"
import { useUpdateEssayMutation, useDeleteEssayMutation } from "./essaysApiSlice"
import { useNavigate, Link } from "react-router-dom"
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap"
import EssayCorrectPayment from "../payments/EssayCorrectPayment"
import { useGetCorrectionsQuery } from "../corrections/correctionsApiSlice"
import useAuth from "../../hooks/useAuth"
// import { useGetThemesQuery } from "../themes/themesApiSlice"
const EditEssayForm = ({ essay }) => {

    const {isSuperuser} = useAuth()

    const { corrections } = useGetCorrectionsQuery("correctionsList", {
        selectFromResult: ({ data }) => ({
            corrections: data?.ids
            .map(id => data?.entities[id])
            .filter(entity => entity.essay === essay._id)
        })
    })

    const [updateEssay, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateEssayMutation()

    const [deleteEssay, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteEssayMutation()

    const navigate = useNavigate()

    const [essayText, setEssayText] = useState(essay.essayText)
    const [essayPublished, setEssayPublished] = useState(essay.published)


    useEffect(() => {
        console.log(isSuccess)
        if (isSuccess || isDelSuccess) {
            setEssayText('')
            setEssayPublished(false)
            navigate('/dash/essays')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onEssayTextChanged = e => setEssayText(e.target.value)
    
    const onPublishedChanged = async (e) => {
        
        setEssayPublished(!essayPublished)

    }
    
    const onSaveEssayClicked = async (e) => {
        if (essayText) {
            await updateEssay({ id: essay.id, essayText, essayPublished })
        } else {
            await updateEssay({ id: essay.id, essayText })
        }
    }

    const onDeleteEssayClicked = async () => {
        
        await deleteEssay({ id: essay.id })
    }

    let canSave
    if (essayText) {
        canSave = [essayText].every(Boolean) && !isLoading
    } 
    let canPublish
    if (essayText) {
        canPublish = essayText.split(" ").length > 200 && !isLoading
    } 

    

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validEssayClass = !essayText ? 'form__input--incomplete' : ''
    
    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    const content = (
        <>
            <p className={errClass}>{errContent}</p>
            <Container>
            <Row>
            <Col sm={8}>
                <p>
                    View and edit your essay here. Any spelling errors will be underlined. These were not displayed to you when you first wrote the essay. 
                </p>
                <Form className="form" onSubmit={e => e.preventDefault()}>
             

                {isSuperuser && <h4>Essay by {essay.username}</h4>}
                <Form.Control as="textarea" rows={4}
                    placeholder="Write your essay here..."
                    className={`form__input ${validEssayClass}`}
                    id="essayText"
                    name="essayText"
                    type="text"
                    autoComplete="off"
                    spellCheck= "true"
                    value={essayText}
                    onChange={onEssayTextChanged}
                />
                    
                </Form>
                <br />
                <Button
                className="custom-btn"
                variant="success"
                title="Save"
                onClick={onSaveEssayClicked}
                disabled={!canSave}
                ><span className="custom-button-class">
                Save Changes
                </span>
                </Button>
                <span> </span>
                <Button
                    variant="warning"
                    disabled={!canPublish}
                    onClick={onPublishedChanged}
                >
                    {essayPublished ? "Un-publish": "Publish"}
                </Button>
                <br />
                <span><small>When publishing/unpublishing, you must verify this action by clicking Save Changes. Publishing is disabled for responses shorter than 200 words.</small></span>
                <p></p>
                <Button size="sm"
                    variant="outline-danger"
                    onClick={onDeleteEssayClicked}
                >
                    Delete (warning, cannot be undone)
                </Button>
                <hr />

                {
                    (corrections?.length > 0) &&
                    <>
                    <Alert>
                        <Alert.Heading>Correction Complete</Alert.Heading>
                        <i>Nicely done. Please see the breakdown of your response below. The feedback provided here is intended to support the development of your writing so that you can take active measures to increase your score in Section 2. We encourage you to reflect on both the positive aspects of this feedback, as well as the constructive pieces of advice provided here.</i>
                        <hr />
                        <h6>1. Response to Theme</h6>
                        <p><b> Score:</b> {corrections[0].responseToTaskScore}/10.</p>
                        <p>{corrections[0].responseToTaskComment}</p>
                        <hr />
                        <h6>2. Thoughts and Ideas </h6>
                        <p><b> Score:</b> {corrections[0].thoughtsScore}/10.</p>
                        <p>{corrections[0].thoughtsComment}</p>
                        <hr />
                        <h6>3. Organisation of Writing and Form of Piece </h6>
                        <p><b> Score:</b> {corrections[0].organisationScore}/10.</p>
                        <p>{corrections[0].organisationComment}</p>
                        <hr />
                        <h6>4. Effectiveness and Fluency of Language </h6>
                        <p><b> Score:</b> {corrections[0].effectivenessScore}/10.</p>
                        <p>{corrections[0].effectivenessComment}</p>
                        <hr />
                        <p><b>Overall Impression and General Steps to Improve</b></p>
                        <p>{corrections[0].overallImpressionComment}</p>
                        <hr />
                        <i>For More Strategies on Essay Writing</i>
                        <Link to="/dash/strategy/section2">
                            <Button variant="success">
                                Section 2 Strategies
                            </Button>
                        </Link>
                    </Alert>
                    
                    </>
                }


                {!essay.correctionDue && corrections?.length <1 &&
                <>
                <EssayCorrectPayment essay={essay} />
                </>
                }
                {essay.correctionDue && <Alert variant="warning">
                    <Alert.Heading>
                        Hold tight, we're reviewing your work.
                    </Alert.Heading>
                        Thank you for availing of our Essay Feedback Service.
                        We aim to have feedback on your essay within 48 hours of you requesting it.
                        We will email you when the correction is complete, and a full breakdown of the feedback will be visible on this page.
                    
                    <hr />
            

                
                </Alert>}
                </Col>

                <Col >
                <b>Comments:</b>
            <p>{essay.quotes[0]}</p>
            <p>{essay.quotes[1]}</p>
            <p>{essay.quotes[2]}</p>
            <p>{essay.quotes[3]}</p>
            <p>The associated theme for this task was <b>{essay.theme}</b></p>
            
                </Col>
            </Row>
        </Container>
</>
    )

    return content
}
export default EditEssayForm