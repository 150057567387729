import React from 'react'
import { useState, useEffect } from 'react'
import { Form , InputGroup, Button } from 'react-bootstrap'
import { useAddNewQuestionMutation } from './questionsApiSlice'
import { useUpdateQuestionMutation } from './questionsApiSlice'
import { useAddNewAnswerMutation } from './answersApiSlice'
import useAuth from '../../hooks/useAuth' 
import { useNavigate } from 'react-router-dom'
const Question = ({unit}) => {

  const {isSuperuser} = useAuth()
  const navigate = useNavigate()

    const [addNewQuestion, {
      isLoading,
      isSuccess,
      isError,
      error
  }] = useAddNewQuestionMutation()


  const [updateQuestion, {
    isLoading: isUpdateQuestionLoading,
    isSuccess: isUpdateQuestionSuccess,
    isError: isUpdateQuestionError,
    error: updateQuestionError
}] = useUpdateQuestionMutation()


    const [addNewAnswer, {
      isLoading: isAnswerLoading,
      isSuccess: isAnswerSuccess,
      isError: isAnswerError,
      error: answerError
  }] = useAddNewAnswerMutation()

  const [questionId, setQuestionId] = useState()
  const [answerIds, setAnswerIds] = useState([])
  const [questionText, setQuestionText] = useState()
  const [answer1, setAnswer1] = useState('')
  const [answer1isCorrect, setAnswer1isCorrect] = useState(false)
  const [answer2, setAnswer2] = useState('')
  const [answer2isCorrect, setAnswer2isCorrect] = useState(false)
  const [answer3, setAnswer3] = useState('')
  const [answer3isCorrect, setAnswer3isCorrect] = useState(false)
  const [answer4, setAnswer4] = useState('')
  const [answer4isCorrect, setAnswer4isCorrect] = useState(false)

  const handleQuestionTextChange = e => setQuestionText(e.target.value)

  const handleAnswer1Change = e => setAnswer1(e.target.value)
  const handleAnswer2Change = e => setAnswer2(e.target.value)
  const handleAnswer3Change = e => setAnswer3(e.target.value)
  const handleAnswer4Change = e => setAnswer4(e.target.value)

  const handleAnswer1isCorrectChange = e => setAnswer1isCorrect(e.target.value)
  const handleAnswer2isCorrectChange = e => setAnswer2isCorrect(e.target.value)
  const handleAnswer3isCorrectChange = e => setAnswer3isCorrect(e.target.value)
  const handleAnswer4isCorrectChange = e => setAnswer4isCorrect(e.target.value)

  const handleAddQuestion = async () => {
    // e.preventDefault()
    console.log("unit id is " + unit)
    const {data: newQuestionData} = await addNewQuestion({"text": questionText, "unit": unit})

    if (isError) {
      console.log("Error adding question" + error)
    }
  
    if (newQuestionData) {
      setQuestionId(newQuestionData.question._id)
      console.log("question id is: " + questionId)
    }

    if (!newQuestionData) {
      console.log("no data")
    }

    // we now have the question saved. Next is to save the answers to the answer model 
    // and keep the answer ids as an array that we'll populate into the questions model.

    const {data: answer1Data} = await addNewAnswer(
      {
        text: answer1,
        isCorrect: answer1isCorrect,
      }
    )
    
    if (answer1Data) {
      console.log("answer1data id is " + answer1Data.answer._id)
      setAnswerIds([answerIds], answer1Data.answer)
      console.log("answerids array is: " + answerIds)
    }

    if (!answer1Data) {
      console.log("no answer 1 data")
    }

    const {data: answer2Data} = await addNewAnswer(
      {
        text: answer2,
        isCorrect: answer2isCorrect,
      }
    )
    
    if (answer2Data) {
      setAnswerIds([answerIds], answer2Data.answer)
      console.log(answerIds)
      console.log(answer2Data.answer)
      
      const {data: updateQuestionData} = await updateQuestion(
        {
          id: questionId,
          answers: answer2Data?.answer,
        }
        )

        if (updateQuestionData) {
          console.log(updateQuestionData)
        }

    }

    // const {answer3Data} = await addNewAnswer(
    //   {
    //     text: answer3,
    //     isCorrect: answer3isCorrect,
    //   }
    // )
    
    // if (answer3Data) {
    //   setAnswerIds(...answerIds, answer3Data.answer._id)
    //   console.log(answerIds)
    // }

    // const {answer4Data} = await addNewAnswer(
    //   {
    //     text: answer4,
    //     isCorrect: answer4isCorrect,
    //   }
    // )
    
    // if (answer4Data) {
    //   setAnswerIds(...answerIds, answer4Data.answer._id)
    //   console.log(answerIds)
    // }

    // we now have all of the ids in an array of answer id's,
    // let's update the question to have these associated with it.

    // const {questionData} = await addNewQuestion(
    //   {
    //     text: questionText,
    //     unit: unit,
    //     answers: answerIds
    //   }
    // )

    // if (questionData) {
    //     console.log(questionData.question.answers)
    // }
    // if (!questionData) {
    //     console.log("no question data")
    // }


    if (isAnswerError || isUpdateQuestionError) {
      console.log("Error adding question" + answerError)
      console.log("Error adding question" + updateQuestionError)
    }

    if (isLoading || isUpdateQuestionLoading) {
      console.log("loading question add")
    }
    if (isAnswerLoading) {
      console.log("loading answer add")
    }

  }

  useEffect(() => {

    if(!isSuperuser){
      navigate('/dash')
    }
    
    if (isSuccess || isUpdateQuestionSuccess) {
      console.log("Question successfully added")

    }
    if (isAnswerSuccess) {
      console.log("Answer successfully added")
      setQuestionText('')
    }

}, [isSuccess, isAnswerSuccess, isSuperuser, isUpdateQuestionSuccess, navigate])

  return (
    // here is the template for a question which should have
    // 4 answers, one of which is correct.
    // The correct one should be marked as correct.
    // There should be reasoning behind all answers as to why
    // each one is correct/incorrect.
    <>
    <h6>Question</h6>
    <Form>
      
      <Form.Control 
        as="textarea"
        placeholder="Question"
        value={questionText}
        onChange={handleQuestionTextChange}
        />
      <br />
        <InputGroup>
            <Form.Control 
              as="input"
              placeholder='answer 1'
              value={answer1}
              onChange={handleAnswer1Change}
            />
            <Form.Control 
              as="input"
              placeholder='comment on answer 1'
            />
            <Form.Check
                  inline
                  label="Correct"
                  name="group1"
                  type='radio'
                  value={answer1isCorrect}
                  onChange={handleAnswer1isCorrectChange}
            />
        </InputGroup>
        <br />
        <InputGroup>
            <Form.Control 
              as="input"
              placeholder='answer 2'
              value={answer2}
              onChange={handleAnswer2Change}
            />
             <Form.Control 
              as="input"
              placeholder='comment on answer 2'
            />
            <Form.Check
                  inline
                  label="Correct"
                  name="group1"
                  type='radio'
                  value={answer2isCorrect}
                  onChange={handleAnswer2isCorrectChange}
            />
        </InputGroup>
        <br />
        <InputGroup>
            <Form.Control 
              as="input"
              placeholder='answer 3'
              value={answer3}
              onChange={handleAnswer3Change}
            />
             <Form.Control 
              as="input"
              placeholder='comment on answer 3'
            />
            <Form.Check
                  inline
                  label="Correct"
                  name="group1"
                  type='radio'
                  value={answer3isCorrect}
                  onChange={handleAnswer3isCorrectChange}
            />
        </InputGroup>
        <br />
        <InputGroup>
            <Form.Control 
              as="input"
              placeholder='answer 4'
              value={answer4}
              onChange={handleAnswer4Change}
            />
             <Form.Control 
              as="input"
              placeholder='comment on answer 4'
            />
            <Form.Check
                  inline
                  label="Correct"
                  name="group1"
                  type='radio'
                  value={answer4isCorrect}
                  onChange={handleAnswer4isCorrectChange}
            />
        </InputGroup>
        <br />
        <Button onClick={handleAddQuestion} variant="warning">Add Question</Button>
    </Form>
    </>
  )
}

export default Question