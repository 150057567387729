import { useSelector } from "react-redux"
import { selectCurrentToken } from "../features/auth/authSlice"
import jwtDecode from 'jwt-decode'

const useAuth = () => {
    const token = useSelector(selectCurrentToken)
    let isSuperuser = false
    let isPro = false
    let status = "Free"

    if (token) {
        const decoded = jwtDecode(token)
        const { email, username, roles, userId } = decoded.UserInfo

        isSuperuser = roles.includes('Superuser')
        isPro = roles.includes('Pro')

        if (isPro) status = "Pro"
        if (isSuperuser) status = "Superuser"

        return { email, roles, isSuperuser, isPro, status, username, userId }
    }

    return { email: '', roles: [], isSuperuser, isPro, status }
}

export default useAuth