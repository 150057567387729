import { useState, useEffect } from "react"
import { useAddNewEssayMutation, useUpdateEssayMutation } from "./essaysApiSlice"
import { Link } from "react-router-dom"
import useAuth from "../../hooks/useAuth"
import { Form, Button, Alert } from "react-bootstrap"
import EssayToolTip from "../../components/EssayTooltip"

const NewEssayForm = ({ theme, quotes, sneakPeek}) => {

    const {userId, username} = useAuth()

    let quotesStringArray = []
    if (quotes?.length) {
        for (let i = 0; i < 4; i++) {
            quotesStringArray = [...quotesStringArray, quotes[i].quoteText + " - " + quotes[i].author] 
        }
    }

    const [addNewEssay, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewEssayMutation()

    const [updateEssay, {
        isLoading: isUpdateLoading,
        isSuccess: isUpdateSuccess,
        isError: isUpdateError,
        error: updateError
    }] = useUpdateEssayMutation()

    const [essayText, setEssayText] = useState('')
    const [saveConfirmationText, setSaveConfirmationText] = useState()
    const [isSaved, setIsSaved] = useState()
    const [essayId, setEssayId] = useState(null)
    
    
    useEffect(() => {
        
        if (isSuccess || isUpdateSuccess) {

            setIsSaved(true)
            setSaveConfirmationText('Essay Saved')
            // navigate('/dash/essays')
        }

    }, [isSuccess, isUpdateSuccess])

    useEffect(() => {
        const handleBeforeUnload = (event) => {
          event.preventDefault();
          event.returnValue = "Are you sure you want to leave this page? Any unsaved changes will be lost.";
        
        };
        if (essayText !== '' && isSaved === false) {
            window.addEventListener("beforeunload", handleBeforeUnload)
            return () => {
                window.removeEventListener("beforeunload", handleBeforeUnload)
            }
        }

      }, [essayText, isSaved])

    const onEssayTextChanged = e => {
        setEssayText(e.target.value)
        setIsSaved(false)
    }

    const onSaveEssayClicked = async (e) => {
        if (essayText && essayId === null) {
            const {data} = await addNewEssay({ essayText, "theme": theme.themeText, "quotes": quotesStringArray, "user": userId, "themeObject": theme, "username": username })

            if (isError) {
                console.log("Error adding essay" + error)
              }

            if (data){
            setEssayId(data.essay._id)
            console.log("essay id is " + data.essay._id)
            }

            if (!data) {
                console.log("no data")
            }
        }

        if (essayText && essayId !== null) {
            await updateEssay({ id: essayId, essayText })
        }

        if (isUpdateError) {
            console.log("error updating essay" + updateError)
        }
        if (isUpdateLoading) {
            console.log("update loading" + isUpdateLoading)
        }

    }


    let canSave
    if (essayText) {
        canSave = [essayText].every(Boolean) && !isLoading
    } 

    const errClass = (isError) ? "errmsg" : "offscreen"
    const validEssayClass = !Boolean(essayText) ? 'form__input--incomplete' : ''
    const spellCheck = false //change to UI element
    const errContent = (error?.data?.message) ?? ''
    const stopPaste = e =>  e.preventDefault()
    const stopCopy = e =>  e.preventDefault()
    


    const content = (
        <>
            <p className={errClass}>{errContent}</p>
            <h4 className="h6-headers">Essay Practice</h4> {sneakPeek ? <small className="paragraphs tinyText">This is a sneak peek 🙈 of what our essay simulation looks like. You can attempt 1 or 2 essays at a time, pick a theme you'd like to write about, or allow us to choose one at random.</small> : ""}  
            {sneakPeek &&
            <>
            <br /><br />
            <Alert className="purpleAlert">
            <p className="paragraphs">
            <a className="smallLinks" href="/signup">Sign Up</a> to practice, save and share your writing. With over 50 themes to help you prepare, you'll be more than ready for Section 2 come exam day. Once you're finished writing an essay, you can opt to receive professional feedback at the click of a button. <EssayToolTip />
            </p>
            </Alert>
            </>
            }
            <Form className="form" onSubmit={e => e.preventDefault()}>
                           
                <Form.Control as="textarea" rows={12}
                    placeholder="Write your essay here..."
                    className={`form__input ${validEssayClass}`}
                    id="essayText"
                    name="essayText"
                    type="text"
                    autoComplete="off"
                    spellCheck={`${spellCheck}`}
                    value={essayText}
                    onChange={onEssayTextChanged}
                    onPaste={stopPaste}
                      onCopy={stopCopy}
                />
                       
            </Form>
            <br />
            
            {!sneakPeek &&
            <>
            <Button
                className="custom-btn"
                variant="outline-light"
                title="Save"
                onClick={onSaveEssayClicked}
                disabled={!canSave}
            >
                <span className="custom-button-class">Save to My Essays</span>
            </Button>
            
            {isSaved && 
                <>
                <br />
                <span className="paragraphs">{saveConfirmationText} 
                </span>
                <Link className="smallLinks" to="/dash/essays">Your Essays</Link>
                </>
            }
            </>
}
{sneakPeek &&
<>
            <Link
            style={{paddingLeft: '10px'}}
            to='/signup'>
            <Button
                className="custom-btn"
                variant="outline-light"
                title="Save"
                
            >
                <span className="custom-button-class">Sign Up to Practice and Save Your Essays</span>
            </Button>
            </Link>
            </>
}
            <br /><br />
        </>
    )
    return content
    }
export default NewEssayForm