import { useGetEssaysQuery } from './essaysApiSlice'
import ProEssayToView from'./ProEssayToView'
import { Container, Table, Button, Col, Row, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
const ProEssaysList = () => {

    const {isPro} = useAuth()
    const navigate = useNavigate()
    useEffect(() => {
        if (!isPro) navigate('/dash')
    })

  const {
    data: essays,
    isLoading,
    isSuccess,
    isError,
    error

  } = useGetEssaysQuery()


  let content 

  if (isLoading) content = 
  <div className="spinner">
    <Spinner animation="border" />
  </div>
  
  if(isError) {
  console.log(error)
    content = 
    <>
    <Container>
    <br />
    {/* <h4 className="errmsg">{error?.data?.message}</h4> */}
    <p>It looks like no one has published an essay yet for others to read. Why not take the lead and share one of yours? </p>
    <Link to="/dash/essays/">
      <Button>
        Go to my essays
      </Button>
    </Link>
    </Container>
    </>
  
  }

  if (isSuccess) {
    const { ids, entities } = essays 

    let filteredIds
    // if (isSuperuser) {
    //   filteredIds = [...ids]
    // } else {

      filteredIds = ids.filter(essayId => entities[essayId].isPro === true)
      
    // }

    const tableContent = ids?.length && filteredIds.map(essayId => <ProEssayToView key={essayId} essayId={essayId} />)
  
    content = (
      
      <Container>
        <Row>
        <Col>
        <br />
        <p>Pro essays are contributions from members who have verified a score of 80+ in GAMSAT section 2.</p>
        <Table striped>
          <thead>
            <tr>
              <th>Essay Snippet</th>
              <th>Written by</th>
              <th>GAMSAT Score</th>
              <th>Theme</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {tableContent}
          </tbody>
        </Table>
        </Col>
        </Row>
      </Container>
    )
  }
  return content 
}

export default ProEssaysList