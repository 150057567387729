import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Public from './components/Public'
import { Login } from './features/auth/Login'
import DashLayout from './components/DashLayout'
import Welcome from './features/auth/Welcome'
import EssaysList from './features/essays/EssaysList'
import NewEssay from './features/essays/NewEssay'
import EditEssay from './features/essays/EditEssay'
import QuotesList from './features/quotes/QuotesList'
import UsersList from './features/users/UsersList'
import EditUser from './features/users/EditUser'
import NewUserForm from './features/users/NewUserForm'
import ThemesList from './features/themes/ThemesList'
import NewThemeForm from './features/themes/NewThemeForm'
import EditTheme from './features/themes/EditTheme'
import NewQuoteForm from './features/quotes/NewQuoteForm'
import EditQuote from './features/quotes/EditQuote'
import Prefetch from './features/auth/Prefetch'
import EssaySettingsForm from './features/essays/EssaySettingsForm'
import PersistLogin from './features/auth/PersistLogin'
import 'bootstrap/dist/css/bootstrap.min.css'
import VerifiedUser from './components/VerifiedUser'
import SignUpForm from './components/SignUp'
import GetVerified from './components/GetVerified'
import PublishedEssaysList from './features/essays/PublishedEssaysList'
import ViewEssay from './features/essays/ViewEssay'
import useTitle from './hooks/useTitle'
import ForgotPassword from './components/ForgotPassword'
import StartingOff from './features/strategy/StartingOff'
import Section1 from './features/strategy/Section1'
import ProEssay from './features/essays/ProEssay'
import ProEssaysList from './features/essays/ProEssayList'
import CorrectionEssaysList from './features/essays/CorrectionList'
import Payment from './features/payments/Payment'
import Completion from './features/payments/Completion'
import Section2 from './features/strategy/Section2'
import About from './components/About'
import Unit from './features/questions/Unit'



function App() {
  useTitle('YakkaGamsat')

  return(
    
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Public />}/>
        <Route path="signup" element={<SignUpForm />}/>
        <Route path="getverified" element={<GetVerified />}/>
        <Route path="verified" element={<VerifiedUser />}/>
        <Route path="login" element={<Login />}/>
        <Route path="forgotpassword" element={<ForgotPassword />}/>
        <Route path="about" element={<About />}/>
        
        <Route element={<PersistLogin />}>
        <Route element={<Prefetch />}>
        
        <Route path="dash" element={<DashLayout />}>
          <Route index element={<Welcome />} />

          <Route path="essays">
            <Route index element={<EssaysList />}/>
            <Route path="new" element={<NewEssay />}/>
            <Route path=":id" element={<EditEssay />}/>
            <Route path="setup" element={<EssaySettingsForm />}/>
            <Route path="published" element={<PublishedEssaysList />}/>
            <Route path="corrections" element={<CorrectionEssaysList />}/>
            <Route path="pro" element={<ProEssaysList />}/>
            <Route path="pro/new" element={<ProEssay />}/>
            <Route path="published/:id" element={<ViewEssay />}/>

          </Route>

          <Route path="quotes">
            <Route index element={<QuotesList />}/>
            <Route path=":id" element={<EditQuote />}/>
            <Route path="new" element={<NewQuoteForm />}/>
          </Route>

          <Route path="users">
            <Route index element={<UsersList />}/>
            <Route path=":id" element={<EditUser />}/>
            <Route path="new" element={<NewUserForm />}/>
            
      
          </Route>

          <Route path="themes">
            <Route index element={<ThemesList />}/>
            <Route path=":id" element={<EditTheme />}/>
            <Route path="new" element={<NewThemeForm />} />
          </Route>

          <Route path="strategy">
            <Route path="startingoff" element={<StartingOff />}/>
            <Route path="section1" element={<Section1 />}/>
            <Route path="section2" element={<Section2 />}/>
          </Route>

          <Route path="payments">
            <Route path="payment" element={<Payment />}/>
            <Route path="completion" element={<Completion />} />
          </Route>

          <Route path="questions">
            <Route index element = {<Unit />}/>
          </Route>
        </Route> {/*end dash*/ }
        
        </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
