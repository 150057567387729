import { useState, useEffect } from "react"
import { useAddNewQuoteMutation } from "./quotesApiSlice"
import { useGetThemesQuery } from "../themes/themesApiSlice"
import { useNavigate } from "react-router-dom"
import { Form, Button, Container } from 'react-bootstrap'

const NewQuoteForm = () => {

    const { themes } = useGetThemesQuery("themesList", {
        selectFromResult: ({ data }) => ({
            themes: data?.ids.map(id => data?.entities[id])
        })
    })

    console.log(themes)

    const [addNewQuote, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewQuoteMutation()

    const navigate = useNavigate()

    const [quoteText, setQuoteText] = useState('')
    const [author, setAuthor] = useState('')
    const [theme, setTheme] = useState('')
    

    useEffect(() => {
        console.log(isSuccess)
        if (isSuccess) {
            setQuoteText('')
            setAuthor('')
            navigate('/dash/quotes/new')
        }

    }, [isSuccess, navigate])

    const onQuoteTextChanged = e => setQuoteText(e.target.value)
    const onAuthorChanged = e => setAuthor(e.target.value)
    const onThemeChanged = e => setTheme(e.target.value)


    const onSaveQuoteClicked = async (e) => {
        if (quoteText && author && theme) {
            await addNewQuote({ quoteText, author, theme })
        } 
    }

    const options = themes?.map(theme => {
        return (
            <option
                key={theme.id}
                value={theme.id}

            > {theme.themeText}</option >
        )
    })

    let canSave
    if (quoteText) {
        canSave = [quoteText, author, theme].every(Boolean) && !isLoading
    } 

    const errClass = (isError) ? "errmsg" : "offscreen"
    const validQuoteClass = !quoteText ? 'form__input--incomplete' : ''
    const validAuthorClass = !quoteText ? 'form__input--incomplete' : ''
    
    const errContent = (error?.data?.message) ?? ''


    const content = (
        <>
            <p className={errClass}>{errContent}</p>
        <Container>
            <Form className="form" onSubmit={e => e.preventDefault()}>
                <div className="form__title-row">
                    <h2>New Quote</h2>
                    
                </div>
                <label className="form__label" htmlFor="quoteText">
                    Quote: </label>
                <Form.Control
                    className={`form__input ${validQuoteClass}`}
                    id="quoteText"
                    text="quoteText"
                    type="text"
                    autoComplete="off"
                    value={quoteText}
                    onChange={onQuoteTextChanged}
                />
                <label className="form__label" htmlFor="author">
                    Author: </label>
                <Form.Control
                    className={`form__input ${validAuthorClass}`}
                    id="author"
                    text="author"
                    type="author"
                    value={author}
                    onChange={onAuthorChanged}
                />

                <label className="form__label form__checkbox-container" htmlFor="theme">
                    Theme:</label>
                <select
                    id="theme"
                    name="theme"
                    className="form__select"
                    value={theme}
                    onChange={onThemeChanged}
                >
                    {options}
                </select>
                <br />
                <Button
                    className="icon-button"
                    title="Save"
                    onClick={onSaveQuoteClicked}
                    disabled={!canSave}
                >
                        Save
                </Button>
            </Form>
            </Container>
        </>
    )

    return content
}
export default NewQuoteForm