import { useState, useEffect } from 'react'
import { Alert, Container, Form, Button } from 'react-bootstrap'
import useAuth from '../../hooks/useAuth'
import { useAddNewEssayMutation } from './essaysApiSlice'
import { useNavigate } from 'react-router-dom'
import ThemeOptions from '../themes/ThemeOptions'
import { useGetThemesQuery } from '../themes/themesApiSlice'
const ProEssay = () => {
  
    const { isSuperuser } = useAuth()
    const navigate = useNavigate()

    const { themes } = useGetThemesQuery("themesList", {
        selectFromResult: ({ data }) => ({
            themes: data?.ids.map(id => data?.entities[id])
        })
    })

    let themeObject

    const {userId, username} = useAuth()
    const [theme, setTheme] = useState('')
    const [themeText, setThemeText] = useState('')
    const [score, setScore] = useState('')

    const [addNewEssay, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewEssayMutation()



    const [essayText, setEssayText] = useState('')
    
    useEffect(() => {

        if (!isSuperuser) navigate('/dash/')
        
        if (isSuccess) {
            setEssayText('')
        
            navigate('/dash/essays/pro/new')
        }

    }, [isSuccess, isSuperuser, navigate])

    const onEssayTextChanged = e => {
        setEssayText(e.target.value) 
        console.log("proessaytheme: " + theme)
        themeObject = themes.filter( object => object._id === theme)
        themeObject = themeObject[0]
        setTheme(themeObject)
        console.log(theme)

    }

    const onScoreChanged = e => {
        setScore(e.target.value)
    }
    
    const onThemeTextChanged = e => {
        setThemeText(e.target.value)
    }

    const onSaveEssayClicked = async (e) => {
        if (essayText) {
            await addNewEssay({ essayText, "user": userId, "themeObject": theme, "theme": themeText, "username": username, "isPro": true, "userScore": score })
    }
    }


    let canSave
    if (essayText) {
        canSave = [essayText, score].every(Boolean) && !isLoading
    } 

    const errClass = (isError) ? "errmsg" : "offscreen"
    const validEssayClass = !Boolean(essayText) ? 'form__input--incomplete' : ''
    const spellCheck = false //change to UI element
    const errContent = (error?.data?.message) ?? ''
    
    


    const content = (
        <>
            <p className={errClass}>{errContent}</p>
            <Container>
            <h6>Add a Pro Essay</h6>
            <Alert variant='warning'>Note that only essays from users who have a verified score of 70+ in section 2 can be included here.</Alert>
            
            <Form className="form" onSubmit={e => e.preventDefault()}>
                <ThemeOptions onThemeSelected = {(theme) => setTheme(theme)} />
                <Form.Control
                    type="text"
                    placeholder="Theme"
                    value={themeText}
                    onChange={onThemeTextChanged}
                />
                <br />
                <Form.Control
                    type="text"
                    placeholder="Section 2 score of user"
                    value={score}
                    onChange={onScoreChanged}
                />
                <br />
                <Form.Control as="textarea" rows={4}
                    placeholder="Write essay here..."
                    className={`form__input ${validEssayClass}`}
                    id="essayText"
                    name="essayText"
                    type="text"
                    autoComplete="off"
                    spellCheck={`${spellCheck}`}
                    value={essayText}
                    onChange={onEssayTextChanged}
                    
                />
                       
            </Form>
            <br />
            <Button
                variant="primary"
                title="Save"
                onClick={onSaveEssayClicked}
                disabled={!canSave}
            >
                Save to Pro Essays
            </Button>
            </Container>
            <br /><br />
        </>
    )
    return content
}


export default ProEssay