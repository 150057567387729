import { useParams } from 'react-router-dom'
import EditEssayForm from './EditEssayForm'
import { useGetEssaysQuery } from './essaysApiSlice'
const EditEssay = () => {
    const { id } = useParams()

    const { essay } = useGetEssaysQuery("essaysList", {
        selectFromResult: ({ data }) => ({
            essay: data?.entities[id]
        }),
    })

    const content = <EditEssayForm essay={essay}/> 

    return content
}
export default EditEssay