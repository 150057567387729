import React from 'react'
import useAuth from '../../hooks/useAuth'
import { useState, useEffect } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import { useLikeToggleMutation } from '../essays/essaysApiSlice'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'

const Likes = ({ essay }) => {
    const {userId} = useAuth()
    const [isLiked, setIsLiked] = useState('')
    const [essayLikes, setEssayLikes] = useState(essay.likes)
    const [likeCount, setLikeCount] = useState(essay.likes?.length)

    const [likeToggle, {
      isLoading,
      isSuccess,
      isError,
      error
  }] = useLikeToggleMutation()

    useEffect(() => {
        if (essayLikes?.includes(userId)) {
            setIsLiked(true)
        }
    },[essayLikes, userId, likeToggle, isLiked])

    let content 
    const handleEdit = async (e) => {

        if (isLiked) {
            return;
        }

        if (!isLiked) {
            await likeToggle({"essayId": essay._id, "likerId": userId})
            setIsLiked(true)
            setEssayLikes(essay.likes)
            setLikeCount(essay.likes.length+1)  
        }
        
    }


    if (isSuccess) {
    }
    

    if (isLoading) content = 
    
    <div className="spinner">
        <Spinner animation="border" />
    </div>

    if(isError) {
        console.log(error)
        content = <p>error</p>
      }

    if (isLiked) {
        content = (
            <Button
            variant="success"
            onClick={handleEdit}
            >
                <FontAwesomeIcon icon={faThumbsUp} /> ({likeCount})
            </Button>
        )
    } else {
        content = (
            <Button
            variant="outline-success"
            onClick={handleEdit}
            >
                <FontAwesomeIcon icon={faThumbsUp}/> ({likeCount})
            </Button>
        )
    }
    
  return content
}

export default Likes