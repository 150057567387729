import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';

function EssayToolTip() {
  return (
    <>

        <OverlayTrigger
          key='right'
          placement='right'
          overlay={
            <Tooltip id={`tooltip-right`}>
                When you write an Essay on YakkaGamsat, spellcheck and copy+paste functions are disabled to reflect exam conditions. When finished, you can review your work to identify any errors you might have made. 
            </Tooltip>
          }
        >
          <span variant="light">
            <FontAwesomeIcon icon={faCircleInfo} /> 
          </span>
        </OverlayTrigger>
    </>
  );
}

export default EssayToolTip;