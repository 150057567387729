import React from 'react'
import { Button, Container, Navbar, Nav, Form, Row, Col, Alert } from 'react-bootstrap'
import { useState, useEffect } from 'react'
import { useNavigate } from "react-router-dom"
import { useForgotPasswordMutation } from '../features/users/usersApiSlice'

const ForgotPassword = () => {

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');

    const [forgotPassword, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useForgotPasswordMutation()

    const navigate = useNavigate()

    const errClass = (isError) ? "errmsg" : "offscreen"
    const errContent = (error?.data?.message) ?? ''

    useEffect(() => {
        console.log(isSuccess)
        if (isSuccess) {
        
            setEmail('')
        
            navigate('/')
        }

    }, [isSuccess, navigate])


    const handleEmailChange = (e) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailRegex.test(e.target.value)) {
          setEmailError('Please enter a valid email address');
        } else {
          setEmailError('');
        }
        setEmail(e.target.value);
      }

    const handleSubmit = async (e) => {
        //submit to usersController

        e.preventDefault();
        if (!emailError && !isLoading) {
          await forgotPassword({ email })
        }
    }

  return (
    <>
        <Navbar bg="dark" variant="dark" expand="lg">
        <Container fluid>
                    <Navbar.Brand href="/"> <img src="./img/YGBrand.png" alt="YG-logo"/> <b>YakkaGamsat</b></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse>
            <Nav className="me-auto"></Nav>
            </Navbar.Collapse>
            </Container>
    </Navbar>
    <Container>
    <Row className="justify-content-md-center">
                <Col md={6}>
    <br />
    <h4>We all forget sometimes...</h4>
    <p>Enter your email address below. If a related account exists you will receive a link to reset your password.</p>
    <Alert variant="danger">If you do not receive an email notification once you send the reset link, check your spam folder.</Alert>
    <Form onSubmit={handleSubmit}>
    <p className={errClass}>{errContent}</p>
    {emailError && <p className="error">{emailError}</p>}
                <Form.Control
                    // className={`form__input ${validEmailClass}`}
                    placeholder="Email"
                    // id="email"
                    name="email"
                    type="text"
                    autoComplete="off"
                    value={email}
                    onChange={handleEmailChange}
                />
        <br />
        <Button
            variant="primary"
            title="Save"
            onClick={handleSubmit}
            // disabled={!canSave}
        >
            Send Password Reset Link
        </Button>
    </Form>
    </Col>
    </Row>
            </Container>
    </>
  )
}

export default ForgotPassword