import { useState, useEffect } from "react"
import { useUpdateUserMutation, useDeleteUserMutation } from "./usersApiSlice"
import { useNavigate } from "react-router-dom"
import { ROLES } from "../../config/roles"
import { Form, Button, Container, Alert } from "react-bootstrap"
import useAuth from "../../hooks/useAuth"

const USER_REGEX = /^[a-zA-Z0-9_]{4,20}$/;
const PWD_REGEX = /^[A-z0-9!@#$%]{4,40}$/

const EditUserForm = ({ user }) => {

    const { isSuperuser, userId } = useAuth()

    const [updateUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useUpdateUserMutation()

    const [deleteUser, {
        isSuccess: isDelSuccess,
        isError: isDelError,
        error: delerror
    }] = useDeleteUserMutation()

    const navigate = useNavigate()

    if (user.id !== userId && !isSuperuser) navigate('/dash/')

    const [username, setUsername] = useState(user.username)
    const [validUsername, setValidUsername] = useState(false)
    const [email] = useState(user.email)
    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)
    const [roles, setRoles] = useState(user.roles)

    const [verifyPassword, setVerifyPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [verifyPasswordError, setVerifyPasswordError] = useState('');

    useEffect(() => {
        setValidUsername(USER_REGEX.test(username))
    }, [username])

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password))
    }, [password])

    useEffect(() => {
        console.log(isSuccess)
        if (isSuccess || isDelSuccess) {
            setUsername('')
            setPassword('')
            setRoles([])
            navigate('/dash/users')
        }

    }, [isSuccess, isDelSuccess, navigate])

    const onUsernameChanged = e => setUsername(e.target.value)

    const handlePasswordChange = (e) => {
        if (e.target.value.length < 8) {
          setPasswordError('Password must be at least 8 characters');
        } else {
          setPasswordError('');
        }
        setPassword(e.target.value);
      }
    
      const handleVerifyPasswordChange = (e) => {
        if (e.target.value !== password) {
          setVerifyPasswordError('Passwords do not match');
        } else {
          setVerifyPasswordError('');
        }
        setVerifyPassword(e.target.value);
      }


    const onRolesChanged = e => {
        const values = Array.from(
            e.target.selectedOptions,
            (option) => option.value
        )
        setRoles(values)
    }

    const onSaveUserClicked = async (e) => {
        e.preventDefault()
        if (!passwordError && !verifyPasswordError) {
            await updateUser({ id: user.id, username, password, email, roles })
        }
    }

    const onDeleteUserClicked = async () => {
        console.log("userID: " + user.id)
        await deleteUser({ id: user.id })
    }
 
    const options = Object.values(ROLES).map(role => {
        return (
            <option
                key={role}
                value={role}

            > {role}</option >
        )
    })

    let canSave
    if (password)  {
        canSave = [roles.length, validUsername, validPassword].every(Boolean) && !isLoading
    } else {
        canSave = [roles.length, validUsername].every(Boolean) && !isLoading
    }

    const errClass = (isError || isDelError) ? "errmsg" : "offscreen"
    const validUserClass = !validUsername ? 'form__input--incomplete' : ''

    const validRolesClass = !Boolean(roles.length) ? 'form__input--incomplete' : ''

    const errContent = (error?.data?.message || delerror?.data?.message) ?? ''


    const content = (
        <>
        <Container>
                <p className={errClass}>{errContent}</p>
            <Form className="form" onSubmit={e => e.preventDefault()}>
                <div className="form__title-row">
                    <h2>Account Settings</h2>
                    <div className="form__action-buttons">
                    </div>
                </div>
                <label className="form__label" htmlFor="username">
                    Username: </label>
                <Form.Control
                    className={`form__input ${validUserClass}`}
                    id="username"
                    name="username"
                    type="text"
                    autoComplete="off"
                    value={username}
                    onChange={onUsernameChanged}
                />

<p></p>
                      {passwordError && <Alert variant="danger" className="error">{passwordError}</Alert>}
                      <Form.Label>New Password</Form.Label>
                      <Form.Control
                    placeholder="Choose Password"
                    // id="password"
                    name="password"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                />
                <p></p>
                {verifyPasswordError && <Alert variant="danger" className="error">{verifyPasswordError}</Alert>}
                <Form.Label>Confirm New Password</Form.Label>
                <Form.Control
                    placeholder="Confirm Password"
                    id="passwordConfirm"
                    name="passwordConfirm"
                    type="password"
                    value={verifyPassword}
                    onChange={handleVerifyPasswordChange}
                />
                <p />


                
                {isSuperuser &&
                <label className="form__label" htmlFor="roles">
                    ASSIGNED ROLES:</label>
}
               {isSuperuser &&
                <Form.Select
                    id="roles"
                    name="roles"
                    className={`form__select ${validRolesClass}`}
                    multiple={true}
                    size="3"
                    value={roles}
                    onChange={onRolesChanged}
                >
                    {options}
                </Form.Select>
}
                <br />
                
                <Button
                            variant="success"
                            title="Save"
                            onClick={onSaveUserClicked}
                            disabled={!canSave}
                        >
                            Save
                        </Button>
                        <span> </span>
                        {isSuperuser && user.roles.includes("Free") &&
                        <Button
                            variant="danger"
                            title="Delete"
                            onClick={onDeleteUserClicked}
                        >
                            Delete User
                        </Button>
}

            </Form>
            </Container>
            </>
    )

    return content
}
export default EditUserForm