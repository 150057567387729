import { useGetEssaysQuery } from './essaysApiSlice'
import EssayToView from './EssayToView'
import useAuth from '../../hooks/useAuth'
import { Container, Table, Button, Spinner } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const CorrectionEssaysList = () => {

  const { isSuperuser } = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    if (!isSuperuser) {
        navigate('/dash')
    }
  },[isSuperuser, navigate])

  const {
    data: essays,
    isLoading,
    isSuccess,
    isError,
    error

  } = useGetEssaysQuery()

  let content 

  if (isLoading) content = 
  <div className="spinner">
    <Spinner animation="border" />
  </div>

  
  if(isError) {
    console.log(error)
    content = 
    <>
    <Container>
    <br />
    {/* <h4 className="errmsg">{error?.data?.message}</h4> */}
    <p>It looks like we're having trouble loading essays to be corrected </p>
    <Link to="/dash/essays/setup">
      <Button>
        Write an Essay
      </Button>
    </Link>
    </Container>
    </>
  }

  if (isSuccess) {
    const { ids, entities } = essays 

    let filteredIds

      filteredIds = ids.filter(essayId => entities[essayId].correctionDue === true)
      


    if (filteredIds.length === 0) {
      content = 
      <>
      <Container>
      <br />
      <p>It looks like there are no essays to correct</p>
      <Link to="/dash/essays/setup">
        <Button>
          Write an Essay
        </Button>
      </Link>
      </Container>
      </>

    } else {

    const tableContent = ids?.length && filteredIds.map(essayId => <EssayToView key={essayId} essayId={essayId} />)
  
    content = (
      
      <Container>
        <br />
        <h3>Essay Corrections</h3>
        <p>These essays need to be corrected</p>
        <Table striped>
          <thead>
            <tr>
              <th>Essay Content</th>
              <th>Created on</th>
              <th>Theme</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {tableContent}
          </tbody>
        </Table>
      </Container>
    )
    }
  }
  return content 
}

export default CorrectionEssaysList