import { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { loadStripe } from "@stripe/stripe-js";
import { Container } from "react-bootstrap";


function Payment() {
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState("");
 
 // testing urls
  // useEffect(() => {
  //   fetch("http://localhost:3500/config").then(async (r) => {
  //     const { publishableKey } = await r.json();
  //     setStripePromise(loadStripe(publishableKey));
  //   });
  // }, []);

  // useEffect(() => {
  //   fetch("http://localhost:3500/create-payment-intent", {
  //     method: "POST",
  //     body: JSON.stringify({}),
  //   }).then(async (result) => {
  //     var { clientSecret } = await result.json();
  //     setClientSecret(clientSecret);
  //   });
  // }, []);

  useEffect(() => {
    fetch("https://yakkagamsat-api.onrender.com/config").then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, []);

  useEffect(() => {
    fetch("https://yakkagamsat-api.onrender.com/create-payment-intent", {
      method: "POST",
      body: JSON.stringify({}),
    }).then(async (result) => {
      var { clientSecret } = await result.json();
      setClientSecret(clientSecret);
    });
  }, []);

  return (
    <>
    <Container className="paymentsSuper">
    <br />
      <h6 className="h6-headers">YakkaGamsat Pro Membership</h6>

      <hr />

      <Container className="paymentsContainer">
      <p className="paragraphs">Pro Members receive full YakkaGamsat platform access for 3 months.</p>
      <p className="paragraphs">Only $29.99.</p>    
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm clientSecret={clientSecret}/>
        </Elements>
      )}
      <p className="smallLinks">Secure Payments by <a className="smallLinks" target="_blank" rel="noreferrer" href="https://stripe.com">Stripe</a></p>
      </Container>

      </Container>
    </>
  );
}

export default Payment;