import { createSelector, createEntityAdapter } from "@reduxjs/toolkit";
import { apiSlice } from "../../app/api/apiSlice"

const feedbackAdapter = createEntityAdapter({})

const initialState = feedbackAdapter.getInitialState()

export const feedbackApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getFeedback: builder.query({
            query: () =>({ 
            url: '/feedback',
            validateStatus: (response, result) => {
                return response.status === 200 && !result.isError
            },
        }),
            transformResponse: responseData => {
                const loadedFeedback = responseData.map(feedback => {
                    feedback.id = feedback._id
                    return feedback
                });
                return feedbackAdapter.setAll(initialState, loadedFeedback)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Feedback', id: 'LIST' },
                        ...result.ids.map(id => ({ type: 'Feedback', id }))
                    ]
                } else return [{ type: 'Feedback', id: 'LIST' }]
            }
        }),
        addNewFeedback: builder.mutation({
            query: initialFeedbackData => ({
                url: '/feedback',
                method: 'POST',
                body: {
                    ...initialFeedbackData,
                }
            }),
            invalidatesTags: [
                { type: 'Feedback', id: "LIST" }
            ]
        }),
        updateFeedback: builder.mutation({
            query: initialFeedbackData => ({
                url: '/feedback',
                method: 'PATCH',
                body: {
                    ...initialFeedbackData,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Feedback', id: arg.id }
            ]
        }),
        deleteFeedback: builder.mutation({
            query: ({ id }) => ({
                url: `/feedback`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Feedback', id: arg.id }
            ]
        }),
    }),
})

export const {
    useGetFeedbackQuery,
    useAddNewFeedbackMutation,
    useUpdateFeedbackMutation,
    useDeleteFeedbackMutation,
} = feedbackApiSlice

// returns the query result object
export const selectFeedbackResult = feedbackApiSlice.endpoints.getFeedback.select()

// creates memoized selector
const selectFeedbackData = createSelector(
    selectFeedbackResult,
    feedbackResult => feedbackResult.data // normalized state object with ids & entities
)

//getSelectors creates these selectors and we rename them with aliases using destructuring
export const {
    selectAll: selectAllFeedback,
    selectById: selectFeedbackById,
    selectIds: selectFeedbackIds
    // Pass in a selector that returns the feedback slice of state
} = feedbackAdapter.getSelectors(state => selectFeedbackData(state) ?? initialState)