import { useState, useEffect } from "react"
import { useSignUpUserMutation } from "../features/users/usersApiSlice"
import { useNavigate, Link } from "react-router-dom"
import { Form, Container, Button, Alert } from "react-bootstrap"

const SignUpForm = () => {

    const [signUpUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useSignUpUserMutation()

    const navigate = useNavigate()

    useEffect(() => {
        console.log(isSuccess)
        if (isSuccess) {
            setUsername('')
            setEmail('')
            setPassword('')
            navigate('/getverified')
        }

    }, [isSuccess, navigate])

    const [username, setUsername] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [verifyPassword, setVerifyPassword] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [verifyPasswordError, setVerifyPasswordError] = useState('');
    const errClass = (isError) ? "errmsg" : "offscreen"
    const errContent = (error?.data?.message) ?? ''
  
    const handleUsernameChange = (e) => {
      const usernameRegex = /^[a-zA-Z0-9_]{4,30}$/;
      setUsernameError('Usernames consist of letters, numbers and underscores, but no spaces.');
      if (!usernameRegex.test(e.target.value)) {
      } else {
        setUsernameError('');
      }
      setUsername(e.target.value);
    }
  
    const handleEmailChange = (e) => {
      const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailRegex.test(e.target.value)) {
        setEmailError('Please enter a valid email address');
      } else {
        setEmailError('');
      }
      setEmail(e.target.value);
    }
  
    const handlePasswordChange = (e) => {
      if (e.target.value.length < 8) {
        setPasswordError('Password must be at least 8 characters');
      } else {
        setPasswordError('');
      }
      setPassword(e.target.value);
    }
  
    const handleVerifyPasswordChange = (e) => {
      if (e.target.value !== password) {
        setVerifyPasswordError('Passwords do not match');
      } else {
        setVerifyPasswordError('');
      }
      setVerifyPassword(e.target.value);
    }
  
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (!usernameError && !emailError && !passwordError && !verifyPasswordError  && !isLoading && username !== '' && email !== '' && password !== '' && verifyPassword !== '') {
        setSubmitted(true)
        await signUpUser({ username, password, email })
      }

    }



    const content = (
        <>
            <Container className='sign-in-container'>
                      {errContent && <Alert variant="danger" className={errClass}>{errContent}</Alert>}
                      <Form className="sign-in-form" onSubmit={e => e.preventDefault()}>      
            <img className="navlogo" src="./YGLogo.png" alt=""/>
                      <span className="navBrandText">
                        YakkaGamsat
                    </span>
                    <hr />
                      <h4 className="h4-headers">Create your account</h4>
                      
                      <Form.Group>
                      {emailError && <Alert className="purpleAlert error">{emailError}</Alert>}
                      Email
                      <Form.Control
                      name="email"
                      type="text"
                      autoComplete="off"
                      value={email}
                      onChange={handleEmailChange}
                      />
                      <p />
                      </Form.Group>
                      <Form.Group>
                      {usernameError && <Alert className="purpleAlert error">{usernameError}</Alert> }
                      Username (what others see)
                      <Form.Control
                        type="text"
                        autoComplete="off"
                        value={username}
                        onChange={handleUsernameChange}
                      />
                      </Form.Group>
                      <p></p>
                      {passwordError && <Alert className="purpleAlert error">{passwordError}</Alert>}
                      Password
                      <Form.Control

                    name="password"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                />
                <p></p>
                {verifyPasswordError && <Alert className="purpleAlert error">{verifyPasswordError}</Alert>}
                Confirm Password
                <Form.Control                    
                    id="passwordConfirm"
                    name="passwordConfirm"
                    type="password"
                    value={verifyPassword}
                    onChange={handleVerifyPasswordChange}
                />
                <p />

                <Button
                    type="submit"
                    className="custom-btn"
                    variant="outline-light"
                    title="Save"
                    onClick={handleSubmit}
                >
                    <span className="custom-button-class">Sign Up</span>
                </Button>
                <br /><br />
                <p>Already a member? <Link class='smallLinks' to="/login">Sign In</Link> </p>
                <p />
            {submitted && !errContent && <Alert variant="success">Creating Your Account... This may take a moment.</Alert>}
            </Form>
            </Container> 
        </>
    )
    return content
    }
export default SignUpForm