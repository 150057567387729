import { createSelector, createEntityAdapter } from "@reduxjs/toolkit"
import { apiSlice } from "../../app/api/apiSlice"

const correctionsAdapter = createEntityAdapter()
const initialState = correctionsAdapter.getInitialState()

export const correctionsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getCorrections: builder.query({
            query: () => ({
                url: '/corrections',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
            },
        }),
            transformResponse: responseData => {
                const loadedCorrections = responseData.map(correction => {
                    correction.id = correction._id
                    return correction
                });
                return correctionsAdapter.setAll(initialState, loadedCorrections)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Correction', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'Correction', id }))
                    ]
                } else return [{ type: 'Correction', id: 'LIST'}]
            }
        }),
        addNewCorrection: builder.mutation({
            query: initialCorrection => ({
                url: '/corrections',
                method: 'POST',
                body: {
                    ...initialCorrection,
                }
            }),
            invalidatesTags: [
                { type: 'Correction', id: "LIST" }
            ]
        }),
        updateCorrection: builder.mutation({
            query: initialCorrection => ({
                url: '/corrections',
                method: 'PATCH',
                body: {
                    ...initialCorrection,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Correction', id: arg.id }
            ]
        }),
        deleteCorrection: builder.mutation({
            query: ({ id }) => ({
                url: `/corrections`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Correction', id: arg.id }
            ]
        }),
    })
})

export const {
    useGetCorrectionsQuery,
    useAddNewCorrectionMutation,
    useUpdateCorrectionMutation,
    useDeleteCorrectionMutation,
} = correctionsApiSlice

export const selectCorrectionsResult = correctionsApiSlice.endpoints.getCorrections.select()

const selectCorrectionsData = createSelector(
    selectCorrectionsResult,
    correctionsResult => correctionsResult.data 
)

export const {
    selectAll: selectAllCorrections,
    selectById: selectCorrectionById,
    selectIds: selectCorrectionIds
} = correctionsAdapter.getSelectors(state => selectCorrectionsData(state) ?? initialState)