import { useParams } from 'react-router-dom'
import EditQuoteForm from './EditQuoteForm'
import { useGetQuotesQuery } from './quotesApiSlice'
import { useGetThemesQuery } from '../themes/themesApiSlice'

const EditQuote = () => {
    const { id } = useParams()

    const { quote } = useGetQuotesQuery("quotesList", {
        selectFromResult: ({ data }) => ({
            quote: data?.entities[id]
        }),
    })

    const { themes } = useGetThemesQuery("themesList", {
        selectFromResult: ({ data }) => ({
            themes: data?.ids.map(id => data?.entities[id])
        })
    })

    const content = <EditQuoteForm quote={quote} themes={themes} /> 

    return content
}
export default EditQuote