import React from 'react'
import { Alert, Container, Accordion, Row, Badge, Card, Button, Col } from 'react-bootstrap'
import useAuth from '../../hooks/useAuth'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const Section2 = () => {

    const {isPro} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (!isPro) navigate ('/dash')
    })

  return (
    <>
    <Container>
        <br />
        <h2>Section 2</h2>
        <Alert variant="success">
            Learn the process of excelling in Section 2 with a proven framework for success. Here, you will learn how to write impressive responses no matter what the prompts throw at you. Following this strategy leading up to the exam will make you comfortable with the prospect of writing under time pressure.
        </Alert>

<>
        Remember those days in high school when you had to write essays for English class? Well, welcome back. Section 2, to me, is somewhat of a neglected middle child of the exam. However, it is where the least amount of relative effort will confer the highest improvement in performance. 
        
        <br /><br />
        In my view, it is a lot easier to increase a Section 2 score from 55 to 65 than it is to do the same for Section 1 or 3, and it all comes down to structure and style.

        In section 2, you will be provided with 2 sets of four quotes. Each set relates to a broad theme which you must provide a written response to. The time allowed for this section is 60 minutes (30 minutes per response), and there is a 5-minute reading time before writing commences.
        <hr />
        </>      
    <Accordion defaultActiveKey={['0', '1', '2', '3', '4', '5', '6', '7', '8']} alwaysOpen>
        <Accordion.Item eventKey="0">
            <Accordion.Header>Section 2 Gives You an Advantage</Accordion.Header>
            <Accordion.Body>
            <p>
            Why is the above statement true? Well, unlike in the other sections, where the content is decided by ACER and an individual’s result might be benefitted by familiarity with a particular subject, Section 2 is unique in that the output is determined by you, the writer. Nailing down some core principles for this section will allow you to enter the exam with confidence, knowing that for at least 60 minutes of this marathon, you will be adding significant points to your overall score. Even if you feel that the Section 1 didn’t go as planned, points dropped there can be clawed back in Section 2. Let's say, for example, you score 54 in Section 1 but manage a 70 in Section 2 - just like I did in my first GAMSAT attempt - you will average a respectable 62 for the first half of the exam. So, if you feel like the humanities is your weakest section, improving your written communication should be a goal - and it is one that you can succeed in reaching.
            </p>
            <p>
            Now, consider the assessment criteria for Section 2
            </p>
            <ul>
                <li>The quality of thinking about a topic; and</li>
                <li>The control of language demonstrated in the development of the response.</li>
            </ul>
            </Accordion.Body>
        </Accordion.Item> 
        <Accordion.Item eventKey="1">
            <Accordion.Header>Avoid the Speculation about Section 2</Accordion.Header>
            <Accordion.Body>
            <p>
                The online world of GAMSAT preparation is rife with speculation about Section 2. Some say that one of the essays should be a personal response and the other a discussion, and some think writing a poem will be unique and therefore valued (do not do this!). There are those who say that one should fill up the pages provided, and others who insist on including a title for each essay. None of these speculations are true, per se, but we shall take a look at what is perhaps the best method for increasing likelihood of a better score.
            </p>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
            <Accordion.Header>Setting a Baseline is Key</Accordion.Header>
            <Accordion.Body>
                <p>
                    If you’re like me, the prospect of sitting down to respond to the theme of four random quotes is anxiety-inducing in and of itself. I remember the sense of dread I felt the first few times I made an effort to write essays on topics I felt I knew nothing about. This must be done, though. Baseline performance is key to identifying your strengths and weaknesses. After completing a session of essays, you will be more aware of both the areas in which you are strong (structuring the essay, for example), and those areas where you might need a bit more oomph (developing thoughtful content, perhaps). You have to start somewhere, and it's better to start now than in the couple of days leading up to the exam.
                </p>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
            <Accordion.Header>Repeat, Repeat, Repeat</Accordion.Header>
            <Accordion.Body>
                <p>
                    For the best possible outcome, it is important that you start writing essays at least a month before the GAMSAT, and that you do it often. When I first sat the GAMSAT and had only a month of preparation, spending an hour each day writing essays seemed like a waste of time. It is not. 25% of this exam's scoring depends on an hour of essay writing. Surely one hour per day can be sacrificed for this, and it should be.
                </p>
                <p>
                    To emphasise this a bit more, 25% of the exam is based 20% of the total allocated time. If you remove the weighting of the score, then 33% of the result is based on 20% of the time. Becoming a master at section 2 is a skill that rewards candidates. 
                </p>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
            <Accordion.Header>How Much Do I Need to Write?</Accordion.Header>
            <Accordion.Body>
            <p>
                In the original paper-based exam, candidates were provided with six blank sheets of lined paper. That’s three pages per essay. One might have wondered “will it look incomplete if I don’t fill out the booklet?” Absolutely not. In general, the essays I wrote were about 2-2.5 pages in length, and once you have a standardised process in place to structure your composition, achieving a final product of this length is inevitable.
            </p>

            <p>
                Now that the exam is in a typed format, the available space for your written response is effectively infinite given the time constraints. The essays I wrote in preparation section 2 are available in the Pro Essays section of YakkaGamsat. These essays range from about 600-800 words in length. They followed the exact same format to how I wrote essays in handwriting, only I had practiced more intently and ensured to follow strict timing when it came to preparation - and this is a key point. You may as well follow the timing of the exam from day 1 of your preparation as it will force you to complete your response on time at a faster rate than, let's say, giving yourself an hour the first time you practice and thereafter reducing the time by five minutes each time to prepare. You should work in a way that allows your performance to eventually meet the exam conditions, not the other way around. 
            </p>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
            <Accordion.Header>Structuring Your Response</Accordion.Header>
            <Accordion.Body>
            <p>
                It is true that your essay will be judged on how it is structured. The candidate whose response is broken down into introductory, main body and concluding paragraphs will outscore those who flood the pages with content in a disorganised manner, leaving no line breaks. The latter comes across as incoherent and unplanned, whereas the former shows clarity of thought, and the mere breakdown of main body paragraphs conveys to the examiner that you have multiple points to make. Remember that quality of thinking is one of the two criteria, so this is a literal no-brainer. At minimum, include:
            </p>
            <ul>
                <li>An Introduction</li>
                <li>Main Body Paragraph 1</li>
                <li>Main Body Paragraph 2</li>
                <li>Main Body Paragraph 3</li>
                <li>Conclusion</li>
            </ul>

            
            <h6><u>Introduction</u></h6>
            <p>
                You want to win over the corrector with your introduction. Have you ever met someone so interesting at first interaction that you've held onto every word they say? Well, you need to become this person when writing your introduction piece. Make a statement that will pique the interest of someone who has been going through countless essays - most of which will they will award an average mark for - and get them to hang on to every word you write. And please, do not begin with "once upon a time..." 
            </p>
            <p>
                The introduction should give the reader an indication of what you will discuss. It is rather sharp and simple. You can make some observations about the topic in question, and use these to reveal your side of the debate. You can also remain neutral, and explain how you will discuss the relevant arguments for/against the issue at hand in the main body before coming to a standpoint.
            </p>
            
            <h6><u>Main Body Paragraphs</u></h6>
            <p>
                The main body paragraphs are where you write the substance of your essay. It is where you reason your points and effectively communicate the messages you have. This is  where the examiner will be assessing your ability to develop a thoughtful response to the theme at hand, as well as the fluency and effectiveness of your language. Note that another aspect of the assessment for section 2 is the structure and form of the piece. The fact that you will have 3 organised main body paragraphs automatically gives you a competitive edge.
            </p>
            <p>
                Each main body paragraph should be used to make a particular point. The most effective way to structure these paragraphs is to follow the PEEL method. This means that each one should be used to Point (identify the point you are making), Explain, give an Example, and Link to your next point (next paragraph). To break this down even more, it is the process of introducing a point, describing what it is and how it is relevant to the topic/debate at hand, providing an example of how this manifests itself in the real world, and then linking it to any subsequent or past points you make / have made.
            </p>
            <p>
                A can’t-go-wrong formula for choosing the right content for these main body paragraphs is to think of them as 'for', 'against', and 'what I think' paragraphs. The first two main body paragraphs are counterarguments to one another, showing the two sides of a debate, whereas in the last one the writer provides the reasoning for their stance on a topic. This last paragraph is of key value to you in that it relays to the corrector that you are not only capable of identifying numerous viewpoints, but that you can compare one with another and validate your position on the topic.  
            </p>

            <h6><u>Conclusion</u></h6>
            <p>
            The conclusion wraps up the essay, summarising what has been discussed and why the points you have made are relevant. It's somewhat of a condensed overview of the work you have put into the response. Think of it as a resume/CV/advertisement for what you've done over the past 30 minutes - you should be highlighting both the depth and effectiveness of what you communicated in your main body paragraphs.  It can, and should, be used to reinforce the message that the writer is trying to make.
            </p>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
            <Accordion.Header>The Content of your Response</Accordion.Header>
            <Accordion.Body>
            <p>
                What you write is just as important as how you write. You have freedom of expression when responding to the theme: there is no penalty for holding a particular stance on a topic, as the objective here is for candidates to display their ability to articulate ideas. You can adopt a persona with strong views when writing your essay if you feel that it will help you solidify the points being made.
            </p>

            <h6>It is Easier to Remain Open</h6>
            <p>
                What I mean by this is that your ability to respond to a theme by seeing it from many different viewpoints will reap much greater reward, as you will show an understanding that although not everyone holds the same opinion as you on a given topic, you're capable of appreciating differences in views. Let’s introduce an example to discuss the process for essay structuring. Take the theme of education. It is important to understand the different stakeholders that exist for a topic, and here the list is inexhaustible:
            </p>
            <ul>
                <li>Students</li>
                <li>Teachers</li>
                <li>Parents</li>
                <li>Universities</li>
                <li>Governments</li>
                <li>Employers, and many more...</li>
            </ul>

            <p>
                Being able to identify the stakeholders affected by a given topic enables us to assess the subject matter in a more rounded way, even if we are steadfast in the position we hold and want to laud. We can identify the points of view of others while promoting our points and policies, and having accounted for others’ views, we prove that we are not ignorant to them. Understanding the stakeholders alone, however, is not the optimal strategy - one must dig deeper. It is important to move beyond the main topic and explore deeper issues that relate to the stakeholders in question. In most, if not all of the statements provided as prompts in section 2, there will be subthemes in addition to the common theme. From the common theme you must extrapolate the subthemes. If there is one in particular you would like to discuss, take it and run. You will be rewarded for identifying a specific issue at hand. For example, in education there are endless subthemes that could come up:
            </p>

            <ul>
                <li>Availability/Access to Education and Equality of Opportunity</li>
                <li>Philosophy of Education and Outcomes</li>
                <li>Advancement in Education Practices</li>
                <li>Importance of Early Years Education</li>
                <li>The Benefits Education has Bestowed on Humanity</li>
            </ul>

            <p>
                These are just some examples of how one can make the content within an essay more substantial, by bringing to the fore some important concepts that relate to the overall theme. This shows the examiner that the writer has the initiative and ability to look beyond the surface and tackle the deeper issues.
            </p>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
            <Accordion.Header>Read, Read, Read</Accordion.Header>
            <Accordion.Body>
            <p>
                I mentioned this in section 1, but the importance of reading should not be understated when approaching the GAMSAT. For section II, the more one reads in the months and weeks leading up to the exam, the better equipped one is with material to back up the points being made in the essays. Exploring literature and journalism/current affairs and infusing these into your work on the day of the GAMSAT will demonstrate sophistication and relevance. Below are some resources I found useful.
            </p>

            <h6>The Economist</h6>
            <p>
            The Economist Newspaper has weekly reporting on global affairs. Every media outlet has a political stance, and although the Economist is not unbiased, it does raise awareness of global issues that might not appear in your local or national media. As mentioned above, it is a strength to be able to see multiple viewpoints on one topic. There is often an introductory offer available for the Economist which you can avail of as you prepare for the exam.
            </p>
            
            <h6>Books</h6>
            <p>
                During university or work, busy schedules can minimise the opportunity to read for leisure. However, if you can make some time to read — whether it’s once daily or weekly — you will put yourself in a better position for both Section 1 and even Section 3. Reading regularly will increase your reading speed, which is highly beneficial in Section 3, whilst also improving your storytelling/argumentative writing ability for Section II.
            </p>
            <p>
                If you are familiar with GAMSAT, you might have heard of A.C. Grayling’s book “The Meaning of Things”. This book contains a plethora of small chapters — most less than a few pages long—, each discussing a particular theme, the very types of themes that Section II essays are likely to encapsulate. 61 topics are covered in this book, and one can dip in and out of it as necessary. The book is best suited if you are struggling to develop an argument for a particular theme, or if you would like to build your understanding of the ‘for’ and ‘against’ arguments for the issues we face in life. In particular, analysing The Meaning of Things will help you to identify the various viewpoints people have on each issue discussed.
            </p>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="8">
            <Accordion.Header>It's No Netflix, But Still</Accordion.Header>
            <Accordion.Body>
            <p>
                There are times when reading is impossible: you’re driving or simply just too tired for sustained focus. Listen to some audio or (if you’re not driving) video content.
            </p>

            <h6>Oxford Union</h6>
            <p>
                Oxford Union, a debating society, has a wide array of video content available on YouTube. Much of it discusses and debates issues relating to the types of themes that appear in Section 2. What is great about these videos is that freedom of speech is generally upheld, and therefore a wide range of viewpoints for a particular topic can be listened to. Philosophers, musicians and politicians appear among the guests. The production is high quality and makes for great viewing.
            </p>
            
            <h6>Public Speakers</h6>
            <p>
                A lot can be learnt from those who are good at public speaking. The oratorical skills of Jordan Peterson and Milton Friedman, for example, are sights to be witnessed. Whether or not you agree with their philosophies is of secondary importance, it is their way of persuading and reasoning with the listener that is unique. If you can emulate these qualities , or qualities of leaders you admire in your written expression, you’re likely to perform well in this section of the exam.
            </p>
            <h6>Podcasts</h6>
            <p>
                The podcast scene is saturated with hours of content that you can listen to passively. Whether you’re on your daily commute or at the gym, it is a productive use of your time to listen to people that interest you talking about topics you care about. Even if you find out one piece of new information from listening to an hour-long podcast whilst working out, this nugget of information might prove useful to an essay you write in the exam.
            </p>
            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
    <hr />
    
    
    <Row>
            <p>Now that you know how to master Section 2, why not get started with our Essay Practice Tool?</p>
            <Link to="/dash/essays/setup">
                <Button variant="warning">
                    Go To Essay Practice
                </Button>
            </Link>
            <br /><br />
            <h6>Preparation Strategies Per Section <Badge bg="secondary">Coming Soon</Badge></h6> 
            
            <Col sm={4}>
            <Card bg="light">
                 
                    <Card.Body>
                        <Card.Title>Starting Off</Card.Title>
                        <Card.Text>
                            Learn the ABCs of GAMSAT Preparation. 
                        </Card.Text>
                        <Link to="/dash/strategy/startingoff">
                            <Button variant="primary">
                                Starting Off Strategy
                            </Button>
                        </Link>
                    </Card.Body>
            </Card>
            <br />
            </Col>
            <Col sm={4}>
            <Card bg="light">
                 
                    <Card.Body>
                        <Card.Title>Section 1</Card.Title>
                        <Card.Text>
                            Getting to grips with what <i>Reasoning in the Humanities and Social Sciences</i> is all about.
                        </Card.Text>
                        <Link to="/dash/strategy/section1">
                            <Button variant="primary">
                                Section 1
                            </Button>
                        </Link>
                    </Card.Body>
            </Card>
            <br />
            </Col>
            <Col sm={4}>
            <Card bg="light">
                 
                    <Card.Body>
                        <Card.Title>Section 3</Card.Title>
                        <Card.Text>
                                The dos and don'ts of preparing for the most feared section of the exam.
                        </Card.Text>
                        {/* <Link to="/dash/strategy/section3"> */}
                            <Button disabled variant="primary">
                                Coming Soon
                            </Button>
                        {/* </Link> */}
                    </Card.Body>
            </Card>
            </Col>
            </Row>

    </Container>
    </>
  )
}

export default Section2