import { useParams } from 'react-router-dom'
import EditThemeForm from './EditThemeForm'
import { useGetThemesQuery } from './themesApiSlice'

const EditTheme = () => {
    const { id } = useParams()

    const { theme } = useGetThemesQuery("themesList", {
        selectFromResult: ({ data }) => ({
            theme: data?.entities[id]
        }),
    })

    const content = <EditThemeForm theme={theme} /> 

    return content
}
export default EditTheme