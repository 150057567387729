import React from 'react'
import { Container, Navbar, Nav, Button } from 'react-bootstrap'

const GetVerified = () => {
  return (
    <>
       <Navbar bg="light" variant="light" expand="lg">
                <Container >
                    {/* <Navbar.Brand href="/"> <img className="nav-image" src="./YGTextColor.png" alt="YG-logo"/></Navbar.Brand> */}
                    <Navbar.Brand href="/"> 
                    <img className="navlogo" src="./YGLogo.png" alt=""/>
                    <span className="navBrandText">
                        YakkaGamsat
                    </span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse>
                    <Nav className="me-auto">
                        
                    </Nav>
                    </Navbar.Collapse>
                    </Container>
                </Navbar>
    <Container>
        <br />
        <h4 className='h4-headers'>Nice, your account set up almost complete!</h4>
        <p className='paragraphs'>Please go to your email inbox and follow the link to verify your account.</p>
        <p className='paragraphs'>You may need to check your spam folder for the verification email.</p>        
        <hr />
        <b>Link to Gmail</b>:
        <br/>
        <br />
        <a href="https://mail.google.com/" target="_blank" rel="noreferrer"><Button variant="outline-secondary"><img src='./img/gmail.png' alt="Gmail Logo"/></Button></a>
        

    </Container>
    </>
  )
}

export default GetVerified