
import { useNavigate } from "react-router-dom"
import { useGetEssaysQuery } from "./essaysApiSlice"
import { memo } from "react"
import { Button } from "react-bootstrap"
import { useGetUsersQuery } from "../users/usersApiSlice"
import { useGetCommentsQuery } from "../comments/commentsApiSlice"


const EssayToView = ({ essayId }) => {
    
    const { essay } = useGetEssaysQuery("essayList", {
        selectFromResult: ({ data }) => ({
            essay: data?.entities[essayId]
        })
    })

    const {user} = useGetUsersQuery("userList", {
        selectFromResult:({ data }) => ({
            user: data?.entities[essay?.user]
        })
    })

    const { comments } = useGetCommentsQuery("commentsList", {
        selectFromResult: ({ data }) => ({
            comments: data?.ids
            .map(id => data?.entities[id])
            .filter(entity => entity.essay === essay?._id)
        })
    })
    

    const navigate = useNavigate()

    if (essay) {
        const handleEdit = () => navigate(`/dash/essays/published/${essayId}`)
        
        let date = new Date(essay.createdAt)
        date = date.toDateString().split(' ').slice(1).join(' ')

        return (
            <tr onClick={handleEdit}>
                <td>{essay.essayText.substring(0,50)} ...</td>
                <td>{user?.username}</td>
                <td>{date}</td>
                <td>{essay.theme}</td>
                <td>{essay.likes?.length}</td>
                <td>{!comments?.length ? 0 : comments.length }</td>
                <td>
                    <Button
                        variant="outline-dark"
                        onClick={handleEdit}
                    >
                        View
                    </Button>
                </td>
            </tr>
    
        )


    } else return null

}
const memoizedEssay = memo(EssayToView)
export default memoizedEssay