import {useState} from 'react'
import { useGetThemesQuery } from './themesApiSlice'
import { Form } from 'react-bootstrap'
const ThemeOptions = ({onThemeSelected}) => {

    const { themes } = useGetThemesQuery("themesList", {
        selectFromResult: ({ data }) => ({
            themes: data?.ids.map(id => data?.entities[id])
        })
    })

    const [theme, setTheme] = useState('')

    const options = themes?.map(theme => {
        return (
            <option
                key={theme.id}
                value={theme.id}

            > {theme.themeText}</option >
        )
    })

    const onThemeChanged = e => {
        setTheme(e.target.value)
        onThemeSelected(e.target.value)
        console.log(theme)
    }

  return (
    <>
        <label htmlFor='theme'>Theme</label>
        <Form.Select id="theme"
            name="theme"
            className="form__select"
            value={theme}
            onChange={onThemeChanged}
        >
            {options}
        </Form.Select>
        <br />
    </>
    )
}

export default ThemeOptions