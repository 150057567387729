import React from 'react'
import { Navbar, Container, Nav, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
const About = () => {
  return (
    <>

    <Navbar bg="light" variant="light" expand="lg">
                <Container >
                    <Navbar.Brand href="/"> 
                    <img className="navlogo" src="./YGLogo.png" alt=""/>
                    <span className="navBrandText">
                        YakkaGamsat
                    </span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse>
                    <Nav className="me-auto">
                        
                    </Nav>
           
                    <Link to="login">
                        <Button style={{'marginRight': '10px'}} variant="outline-dark">Sign In</Button>
                    </Link>                    
                    <Link to="/signup">
                    <Button className="custom-btn" variant="outline-light">
                        <span className="custom-button-class">Sign Up</span>
                    </Button>
                    </Link>
                    </Navbar.Collapse>
                    </Container>
                </Navbar> 
    <Container>   
    <h6 className='h6-headers'>Who are we?</h6>


    <p className='paragraphs'>
    Hey 👋. I'm year 3 medical student who first built YakkaGamsat in 2023. For the past few years, I have been helping aspiring medical students succeed in the GAMSAT. I aced the GAMSAT following simple exam-oriented strategies. What struck me at the time was the number of exploitative GAMSAT preparation services that charge a lot of money but often miss the point when it comes to effective exam preparation. They often provide a lot of content without necessarily conferring adequate skill onto the candidate seeking to excel in the GAMSAT. Most of the materials you need are already out there - effective preparation comes down to how you use them.
    <br /><br />
    The general advice I usually give is to practice under exam conditions as much as possible, a key reason I developed the Essay Practice tool for section 2, which offers 50+ prompt themes (and counting!). It is equally important to reflect and receive feedback on the work you do, and so I've made it possible for YakkaGamsat members to publish their essays at the click of a button for all other members to view and respond to if they wish. Scoring in the 100th percentile in section 2, I have been helping others improve their scores successfully for the past 2 years, and now I can help you too - whenever you use the Essay Practice tool and save your writing, a professional correction that I will personally see to is just one click away.
    <br /><br />
     YakkaGamsat aims to be a "no-brainer" exam preparation tool that all members can extract significant value from. The pricing for our Pro membership comes in at less than $0.50 per day - accessible to everyone. I plan to develop the platform to offer more tools for section 1 and section 3, and to improve the site's usability. Thanks for the support and good luck with your exam preparation. You got this 🤘.
     </p>
     <Link to="/login">
                        <Button style={{'marginRight': '10px'}} variant="outline-dark">Sign In</Button>
                    </Link>                    
                    <Link to="/signup">
                    <Button className="custom-btn" variant="outline-light">
                        <span className="custom-button-class">Sign Up</span>
                    </Button>
                    </Link>
     </Container>
     <br />
    </>
  )
}

export default About