import React, { useState } from 'react'
import { Alert, Button, Form, InputGroup } from 'react-bootstrap'
import { useCorrectEssayMutation } from './essaysApiSlice'
const CorrectEssay = ({essay}) => {

  console.log(essay)

  const [correctEssay, {
    // isLoading,
    isSuccess,
    // isError,
    // error
}] = useCorrectEssayMutation()

  //variables relating to essay correction
  const [responseToTaskScore, setResponseToTaskScore] = useState()
  const [responseToTaskComment, setResponseToTaskComment] = useState()
  const [thoughtsScore, setThoughtsScore] = useState()
  const [thoughtsComment, setThoughtsComment] = useState()
  const [organisationScore, setOrganisationScore] = useState()
  const [organisationComment, setOrganisationComment] = useState()
  const [effectivenessScore, setEffectivenessScore] = useState()
  const [effectivenessComment, setEffectivenessComment] = useState()
  const [overallImpressionComment, setOverallImpressionComment] = useState()
  

  //functions to update variables

  const handleResponseToTaskScoreChange = e => setResponseToTaskScore(e.target.value)
  const handleResponseToTaskCommentChange = e => setResponseToTaskComment(e.target.value)
  const handleThoughtsScoreChange = e => setThoughtsScore(e.target.value)
  const handleThoughtsCommentChange = e => setThoughtsComment(e.target.value)
  const handleOrganisationScoreChange = e => setOrganisationScore(e.target.value)
  const handleOrganisationCommentChange = e => setOrganisationComment(e.target.value)
  const handleEffectivenessScoreChange = e => setEffectivenessScore(e.target.value)
  const handleEffectivenessCommentChange = e => setEffectivenessComment(e.target.value)
  const handleOverallImpressionChange = e => setOverallImpressionComment(e.target.value)

  
  const handleSubmit = async (e) => {
    console.log("submitted")

    e.preventDefault()
    await correctEssay({
      "essay": essay, 
      responseToTaskScore, 
      responseToTaskComment,
      thoughtsScore,
      thoughtsComment,
      organisationScore,
      organisationComment,
      effectivenessScore,
      effectivenessComment,
      overallImpressionComment
    })

    if (isSuccess) {
      console.log('essayCorrected')
    }

    //handle correction of essay save in the backend

  }


  let content  = (

    <>
    <Alert variant="success">
    <Alert.Heading>Correction</Alert.Heading>
    </Alert>
    <Form onSubmit={handleSubmit}>
      
      <label>Response to Task/Theme</label>
    <InputGroup>
      <Form.Control 
      onChange={handleResponseToTaskScoreChange} 
      value={responseToTaskScore} 
      placeholder="Score" />
      <Form.Control 
      as="textarea"
      onChange={handleResponseToTaskCommentChange} 
      value={responseToTaskComment}
      placeholder="Comment" />
    </InputGroup>

      <label>Thoughts offered by the writer</label>
    <InputGroup>
      <Form.Control 
      onChange={handleThoughtsScoreChange} 
      value={thoughtsScore} 
      placeholder="Score" />
      <Form.Control 
      as="textarea"
      onChange={handleThoughtsCommentChange} 
      value={thoughtsComment}
      placeholder="Comment" />
    </InputGroup>

      <label>Organisation: Shape and Form</label>
    <InputGroup>
      <Form.Control 
      onChange={handleOrganisationScoreChange} 
      value={organisationScore} 
      placeholder="Score" />
      <Form.Control 
      as="textarea"
      onChange={handleOrganisationCommentChange} 
      value={organisationComment}
      placeholder="Comment" />
    </InputGroup>

      <label>Effectiveness and Fluency of Language</label>
    <InputGroup>
      <Form.Control 
      onChange={handleEffectivenessScoreChange} 
      value={effectivenessScore} 
      placeholder="Score" />
      <Form.Control 
      as="textarea"
      onChange={handleEffectivenessCommentChange} 
      value={effectivenessComment}
      placeholder="Comment" />
    </InputGroup>
      
      <label>Overall Impression</label>
    <InputGroup>
      <Form.Control 
      onChange={handleOverallImpressionChange} 
      value={overallImpressionComment} 
      placeholder="Comment" />
    </InputGroup>
      
      <hr/>
      <Button onClick={handleSubmit} variant="warning">
        Submit Corrrection
      </Button>
    </Form>
    
    </>

  )

  return content
}

export default CorrectEssay