import { Container, Col, Navbar, Nav, Button, Card, Row, Accordion } from "react-bootstrap"
import { Link } from 'react-router-dom'
import NewEssayForm from "../features/essays/NewEssayForm"
import Timer from "../features/essays/Timer"
import { useState } from "react"
import TypeIt from "typeit-react"




const Public = () => {
    
    const [theme] = useState('Perseverance')
    const [showOrHide, setShowOrHide] = useState('Reveal Theme')
    const themeVisible = () => {
        let x = document.getElementById("themeText");
        if (x.style.display === "none") {
          x.style.display = "inline";
          setShowOrHide("Theme: Altriusm")
        } else {
          x.style.display = "none";
          setShowOrHide("Reveal Theme")
        }
    }

    let gamsat_date = new Date('03/23/2024')
    let today= new Date()

    let difference = gamsat_date.getTime() - today.getTime()
    let difference_in_days = Math.ceil(difference / (1000*3600*24))



    const content = (   
        <>

            <Navbar bg="light" variant="light" expand="lg">
                <Container >
                    <Navbar.Brand href="/"> 
                    <img className="navlogo" src="./YGLogo.png" alt=""/>
                    <span className="navBrandText">
                        YakkaGamsat
                    </span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse>
                    <Nav className="me-auto">
                        
                    </Nav>
           
                    <Link to="login">
                        <Button style={{'marginRight': '10px'}} variant="outline-dark">Sign In</Button>
                    </Link>                    
                    <Link to="/signup">
                    <Button className="custom-btn" variant="outline-light">
                        <span className="custom-button-class">Sign Up</span>
                    </Button>
                    </Link>
                    </Navbar.Collapse>
                    </Container>
                </Navbar>
   

            <div className="jumbotron">
                
                <section className=" text-center">
                    
            <div className="container" id="imageblock">
                    <h1 className="jumbo-header">
                        <TypeIt
                            getBeforeInit={(instance) => {
                                instance.type("Gamsat Section 2. Mastered. ")
                                // instance.type("Gamsat Section 2. Mastered").pause(750).delete(2).pause(500).type("ered. ")
                                return instance
                            }}
                            element={"h1"} > 
                        </TypeIt>
                    </h1>
                {/* <h2 className="jumbo-subheader">Not sure where to start when it comes to GAMSAT? We've got you covered. Learn the optimal strategies and behaviours required to succeed in this  high-stakes exam.</h2> */}
                <h2 className="jumbo-subheader">
                    The next GAMSAT is in <b>{Math.round(difference_in_days/7)} weeks</b>. Prepare under exam conditions and learn simple strategies to ace section 2. 
                </h2>
                {/* <Alert variant="success" style={{marginBottom: '25px'}} className="public-alert">Exam-Style Section 2 Practice is Now Live 🚀</Alert> */}
                    {/* <SignUpModal className="get-started" textContent={"Get Started →"} /> */}
                    <Link to="/signup">
                    <Button className="custom-btn get-started" variant="success">Sign Up</Button>
                    </Link>
                    </div>
                </section>                    
            </div>
<br />


<div className="bg-container">

    <Container className="public-container">

    <p className="intros">
    👇 Sign up to access our ever-evolving resources 
    </p>
    {/* <h5>
    <Badge bg="dark">Your Dashboard</Badge>
    </h5> */}
<Row>
            {/* <h6 className='h6-headers'>All You Need for Section 2</h6>
            <p className='paragraphs'>
                Practice writing. Share with others. Get Professional Feedback. All in One Place.
            </p> */}

            <h6 className='h6-headers'>Exam Practice</h6>
            <p className='paragraphs'>
                Prepare for section 2 - with exam conditions! We offer the closest thing to the exam-day experience you can get with our like-for-like section 2 simulator. You can easily toggle the settings for a difficulty of your liking.
            </p>

            <h6 className='h6-headers'>Get Free Feedback</h6>
            <p className='paragraphs'>
                Publish your essays and get free feedback from others. No need to mess around with word documents - it's all done seamlessly within the platform.
            </p>

            <h6 className='h6-headers'>Receive Feedback from a Pro</h6>
            <p className='paragraphs'>
                Want in-depth feedback?  With our essay correction service, it's as simple as the click of a button. Corrections are made by a 100th percentile past candidate using an objective set of assessment criteria, and you will see a breakdown of this when your feedback is returned to you.
            </p>

            <h6 className='h6-headers'>Learn from the Pros</h6>
            <p className='paragraphs'>
                Why not see what section 2 success looks like? In the <i>Pro Essays</i> section of the platform, you can read essays that have been submitted from candidates scoring in the 95th or higher percentile in section 2. 
            </p>
        
            <Col sm={3}>
            {/* <Card bg="light">
                 <Card.Img variant="top" className="dashboard-card-img" src="./img/writing.jpg" />
                    <Card.Body>
                        <Card.Title>Section 2 Practice</Card.Title>
                        <Card.Text>
                            Get ready for Section 2 by experiencing the exact exam conditions you will face on the day.  50+ topics to test your writing ability. 
                        </Card.Text>
                        <SignUpModal textContent={"Practice Now"} />
                    </Card.Body>
            </Card>
            <br />
            </Col>
            
            <Col sm={3}>
            <Card bg="light">
            <Card.Img variant="top" className="dashboard-card-img" src="./img/essays.jpg" />
                    <Card.Body>
                        <Card.Title>Corrections</Card.Title>
                        <Card.Text>
                            Want to know how your writing's coming along? When you complete an essay you can send it for a professional correction. 
                        </Card.Text>
                        <SignUpModal textContent={"Go to Your Essays"} />
                    </Card.Body>
            </Card>
            <br />
            </Col>
            
            
            <Col sm={3}>
            <Card bg="light">
            <Card.Img variant="top" className="dashboard-card-img" src="./img/sharing.jpg" />
                    <Card.Body>
                        <Card.Title>Start a Conversation</Card.Title>
                        <Card.Text>
                            Want some free feedback? Share your writing with other YakkaGamsat members and get constructive feedback from the YakkaGamsat community. 
                        </Card.Text>
                        <SignUpModal textContent={"View Published Essays"} />
                    </Card.Body>
            </Card>
            <br />
            </Col>

            <Col sm={3}>
            <Card bg="light">
                <Card.Img variant="top" className="dashboard-card-img" src="./img/fountainpen.jpg" />
                    <Card.Body>
                        <Card.Title>Pro Essays</Card.Title>
                        <Card.Text>
                            Learn from the best with access to essays from candidates scoring in the 100th percentile.
                        </Card.Text>
                        <SignUpModal disabled textContent={"View Pro Essays"} />
                    </Card.Body>
            </Card>
        <br />*/}
            </Col> 
</Row>
            {/* <Alert variant="secondary-warning"> */}
            <Row>
            {/* <h6 className='h6-headers'>Preparation Strategies</h6> 
            <p className='paragraphs'>We keep it simple by offering straightforward, high-yield strategies for GAMSAT preparation.</p> */}

            <h6 className='h6-headers'>Preparation Strategies</h6> 
            <p className='paragraphs'>In addition to section 2 exam simulation software and written samples, we provide you with simple strategies you can employ to succeed in this exam across all three sections.</p>
            {/* <Col sm={3}>
            <Card bg="light">
                 
                    <Card.Body>
                        <Card.Title>Kickstart Your Preparation</Card.Title>
                        <Card.Text>
                            Hit the ground running with digestible advice for those starting their Gamsat Journey.
                        </Card.Text>
                        <SignUpModal textContent={"Let's Go!"} />
                    </Card.Body>
            </Card>
            <br />
            </Col>

            <Col sm={3}>
            <Card bg="light">
                 
                    <Card.Body>                
                        <Card.Title>Section 1 </Card.Title>
                        <Card.Text>
                            How to approach the Humanities and Social Sciences Section.
                        </Card.Text>
                        
                            <Button className="custom-btn" disabled variant="outline-light">
                                Coming Soon
                            </Button>
                        
                    </Card.Body>
            </Card>
            <br />
            </Col>
            <Col sm={3}>
            <Card bg="light">
                 
                    <Card.Body>
                        <Card.Title>Section 2</Card.Title>
                        <Card.Text>
                            A strategy with techniques to ensure you will write excellent responses to the prompts on exam day - from a candidate who scored in the 100th percentile. 
                        </Card.Text>
                        <SignUpModal disabled textContent={"Master Section 2"} />
                    </Card.Body>
            </Card>
            <br />
            </Col>
            <Col style={{paddingBottom: '25px'}} sm={3}>
            <Card bg="light">
                 
                    <Card.Body>
                        <Card.Title>Section 3</Card.Title>
                        <Card.Text>
                                The dos and don'ts of preparing for the final section of the exam, no matter what your background.
                        </Card.Text>
                        
                            <Button className="custom-btn" disabled variant="outline-light">
                                Coming Soon
                            </Button>
                        
                    </Card.Body>
            </Card>
            </Col> */}
            </Row>  
    </Container>
    </div>
    <div>

    <Container className="public-container">
            <br /><br />
            <Row>
                <Col sm={8}>
                    <NewEssayForm sneakPeek={true} />
                </Col>
                <Col>
                    
                    
            <Card>
            <Accordion defaultActiveKey="">
        <Accordion.Item eventKey="0">
            <Accordion.Header><b>Task Comments</b></Accordion.Header>
            <Accordion.Body>
                <i><p>
                    Consider the following comments and develop a piece of writing in response to one or more of them
                </p>
                <p>
                    Your writing will be judged on the quality of your response to the theme, how well you organise and present your point of view, and how effectively you express yourself.
                </p>
                </i>
            </Accordion.Body>
        </Accordion.Item> 
        </Accordion>
                <Card.Body>
                    <Card.Text>
                    Altruism is a process that transforms small acts of kindness into a way of life - <i> Mark R. Leary</i>             
                    </Card.Text>
                    <Card.Text>
                    Altruism is not selflessness, it is the ultimate form of self-interest - <i> Martin Seligman</i>
                    </Card.Text>
                    <Card.Text>
                    Altruism is the moral spine of society - <i>  Matthew Lieberman </i>
                    </Card.Text>
                    <Card.Text>
                    Altruism is not something that can be forced upon a person, it must come from within - <i> Jane Goodall </i>
                    </Card.Text>
                    <Button variant="outline-dark" onClick={themeVisible}>{showOrHide} <span style={{display: 'none'}} id="themeText"><i> {theme.themeText}</i></span></Button> 
                 </Card.Body>
                
            </Card>
            <br />
            <Timer/>
                </Col>
            </Row>
            </Container>
            <br />
            </div>
            {/* <div className="bg-container">
            <FreeVsPro />
            </div> */}
            <Container>
            <br />
            {/* <h6 className='h6-headers'>Who are we?</h6> */}

            {/* <p className='paragraphs'>
                Just a med student simplifying GAMSAT Preparation and making it accessible to everyone. Content for Sections 1 and 3 to be delivered for the September 2023 sitting.
                <br /> 
            <Link to="about">
            <span className="smallLinks">See more...</span>    
            </Link>    
            </p> */}
            <br />
            {/* <About /> */}
            
            <center> &#169; 2023 YakkaGamsat</center>
            <br />
            <br />
            </Container>
            
    </>
    )

    return content 
}

export default Public