import {useEffect, React} from 'react'
import { Alert, Container, Accordion, Row, Badge, Card, Button, Col } from 'react-bootstrap'
import useAuth from '../../hooks/useAuth'
import { useNavigate, Link } from 'react-router-dom'

const StartingOff = () => {

    const {isPro} = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
        if (!isPro) navigate ('/dash')
    })


  return (
    <>
    <Container>
        <br />
        <h2>Starting Off</h2>
        <Alert variant="info">
            In this section, we introduce you to the Gamsat with the most relevant tasks that you can do on day one. We aim to provide you with not too much, not too little information at this point so that you can kickstart your preparation in a realistic manner. The Gamsat is a unique exam that challenges candidates across a wide range of subject matter. We believe it is important to take a well-rounded approach to each section so as to maximise outcomes and reduce the risk of one section causing detriment to an overall score. It is up to you how much or little of this advice you read, and we have organised it in a way that makes it easy to pick and choose the information that most appeals to you - simply click on the headings to view the related content.
        </Alert>
        
    <Accordion defaultActiveKey="">
        <Accordion.Item eventKey="0">
            <Accordion.Header>What is the GAMSAT?</Accordion.Header>
            <Accordion.Body>
            <p>
                If you are completely unfamiliar with the exam, this is for you. The Graduate Medical School Admissions Test (GAMSAT) is the bona fide examination for prospective Graduate Entry Medicine, Dentistry or Veterinary Medicine in certain countries. It is used as a selection measure for Australian, Irish and some UK medical schools. Needless to say, those undertaking the GAMSAT should already have, or be in the process of obtaining, an <i>undergraduate degree</i>. If you are applying to a specific university, it is wise to research and/or communicate with its admissions office to determine the requirements needed to be considered for a place in your desired programme, as there may be additional boxes to tick (welcome to the world of medicine).
            </p>
            
            <span>
                The exam is composed of three sections:
                <ol>
                <li>Reasoning in humanities and social sciences (MCQ) </li>
                <li>Written communication</li>
                <li>Reasoning in biological and physical sciences (MCQ)</li>
                </ol>
            </span>
                <h6>Weighting of Exam Sections</h6>
            <p>
            Officially, the exam sections are weighted in a 1:1:2 ratio. Sections 1 and 2 are worth 25% each, and section 3 is worth 50%. However, in some cases universities remove the weighting and use the average of all three sections. Sections 1 and 3 consist of multiple choice questions (MCQs). Each question has 4 answers to choose from, of which one is correct.
            </p>
            <h6>Context Switching</h6>
            <p>
            As you can probably imagine, the sections of the exam are vastly different from one another, and this makes it particularly challenging as candidates are required to perform well across multiple disciplines. Furthermore, within each section there are multiple subtopics and problem sets that a candidate must analyse and respond to, jumping from one to another as efficiently as possible. This is known as context switching, and to the unfamiliar it is rapidly fatiguing exercise - a reason why adequate preparation relies so heavily on placing yourself in exam-like conditions so as to train your body for this exam.
            </p>
            <p>
            The exam is both intensive and lengthy - lasting a total of 5 hours and 25 minutes across all three sections. In the traditional sitting, the exam was completed in a single day with a one-hour break between section 2 and section 3. Since 2020 the exam sections have been performed across multiple sittings, somewhat relieving candidates of the pressure it entails (but not fully!). However, in 2023 the exam is returning to a full-day event in which all sections are performed in one sitting.
            </p>
            <p>
                Finer details of the exam sections are available in our preparation strategies, but for now, take the following key points. 
            </p>
            <hr />
            <Alert variant="success">
                <h6>Key Points</h6>
                <span>
                <ul>
                    <li>One exam, three sections. Humanities, Written Communication, Sciences.</li>
                    <li>A lot of context switching across multiple disciplines and subtopics.</li>
                    <li>An endurance exam lengthy in nature.</li>
                </ul>
                </span>
                For a full outline on the exam and the specifics of upcoming examination dates and registration information, refer to the <a target="_blank" rel="noreferrer" href="https://gamsat.acer.org/files/GAMSAT_Information_booklet.pdf">GAMSAT Information Booklet 2023</a> provided by ACER, the exam's governing body.
            </Alert>

            </Accordion.Body>
        </Accordion.Item> 
        <Accordion.Item eventKey="1">
            <Accordion.Header>Why is this exam unique?</Accordion.Header>
            <Accordion.Body>
            <p>
                In exams you have prepared for in the past, either at high school or during your undergraduate degree, it is likely that an optimal strategy for success would have been to revise the course content and, in many cases, memorise information. However, such techniques are relatively futile when preparing for the GAMSAT. It is much more important to solidify your understanding of core scientific principles - but not the specifics - and apply them to the problem sets the exam to you. 
            </p>
            <p>
                The above is particularly true for section 3, where your score will be influenced much more by your problem solving ability than your long-term memory. It is true that familiarity with some concepts will place you in a much better position to quickly grasp the content of the exam's questions, but the finer details are not as important as you might expect.
            </p>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
            <Accordion.Header>All sections deserve respect</Accordion.Header>
            <Accordion.Body>
                <p>
                    It is important to acknowledge that the exam consists of three separate sections, and that your overall result is determined by performance <b>across all three</b>. Adequate preparation for all sections will confer a better overall result. 
                </p>
                <p>
                    It is too easy to become bogged down or fearful of one section in isolation, but this is a cardinal sin when it comes to exam preparation. Most candidates will have one section they feel most comfortable with, but it is important to not become complacent. Allocate time to prepare for each section in a way that works for you, but don't neglect preparing for a section out of a feeling of pure confidence.
                </p>
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
            <Accordion.Header>Most Important: Test Yourself at the Beginning</Accordion.Header>
            <Accordion.Body>
            <h6>Do a Dry Run</h6>
                <p>
                    If there is one thing each Gamsat candidate should seek to do early on in their preparation, it is to perform a dry run of the exam by completing a full mock paper under timed conditions. This involves putting oneself in the exact situation they will expect on exam day. The reason for this is that oftentimes candidates are unaware of what is expected of them in terms of endurance, continuously context switching and all the while answering questions on the information presented to them. It is impossible to estimate the true challenge of the GAMSAT until you experience it for yourself. Unless you put yourself in the undesirable and vulnerable position of testing yourself with exam conditions, you will be in for a surprise come exam day. 
                </p>
                <Alert variant='danger'>
                    <h6>But I haven't even studied yet, you're hardly expecting me to test myself already?</h6>
                    <p>
                        This is a reasonable question, and if you haven't been convinced that taking a practice test is right for you, read the following. The GAMSAT is a question of your capacity to reason, not a test of your ability to regurgitate scientific knowledge. It is reasonable at this point to fear that you will fail miserably at a test run. But it is more reasonable to fail and identify any gaps in your preparation now and fix them preemptively, instead of waiting until the day of the exam to see the errors of your ways. Biting the bullet and undertaking a trial run will help you to quickly realise the types of skills required for success in the GAMSAT.
                    </p>
                </Alert>
                <p>
                    Next, we explain how to create this setup and, importantly, how to reflect on this first experience with the exam content and time restraints. 
                </p>
                <h6>Step One: Get that Practice Paper</h6>
                <p>
                    ACER, the governing body of the GAMSAT, offers three full-length Practice Papers, as well as two sets of condensed, sample question booklets - all of which can be found <a target="_blank" rel="noreferrer" href="https://gamsat.acer.org/prepare/preparation-materials">here</a>. Candidates should make it a priority to access these resources in preparation for the exam. We will direct you to external learning resources for all sections of the GAMSAT, most of which are free of charge and highly beneficial, but obtaining the official ACER materials is most important. These are the most accurate reflection of what you will see in the exam itself. Across the official ACER resources there are 290 sample questions for Section 1 and 436 for section 3. Some argue that the cost of buying these isn’t worth it, but it is a relatively small fee to pay when you compare it to the cost of (i) the GAMSAT application fee and (ii) medical school itself. That being said, if financial circumstances make it impossible for you to access these materials through the official website, you may be able to find them somewhere elsewhere online.
                </p>
                    <Alert variant="warning">
                        Yes, the official ACER papers come at a cost, but it is difficult to overemphasise the importance of these resources when it comes to effective Gamsat preparation. There are plenty of organisations that claim to have question banks of Gamsat-style questions, but in most cases they do not reflect what you will face on exam day. 
                    </Alert>
                <h6>Step Two: Creating the Set Up</h6>
                <p>
                    With the ACER resources acquired, pick a day in the near future to set aside and sit an entire practice test. Given the exam is spread over multiple days, it is acceptable to test yourself in a way that reflects this: choose a day to do a trial run of Section 1 and 2, and another day for Section 3. Do not undermine your preparation by sneaking a look at the questions beforehand as it will only undermine the credibility of your performance - and what really is the point of cheating yourself? Yes it's going to be difficult, but this is the first pressure test that sets the baseline for all of your future effort - you should only get better from here! Sit the exam following the guidelines set out by ACER, strictly abiding by the exam conditions and timing, explained below - note that the timings are relative to the official ACER practice papers, which have more questions than the current Gamsat exam, and therefore more time is allocated overall. However, the time allocated per question is roughly the same.
                </p>
                <p><b>Timings</b></p>
                <p>Section 1</p>
                <ul>
                    <li>10 Minutes Reading time - no writing allowed, meaning you cannot scribble or write rough work during this time.</li>
                    <li>100 Minutes Writing time (1hr 40mins) to answer MCQs.</li>
                    <li>Section II follows immediately after - do not stand up to stretch in between these sections, as these are the rules to follow on the day.</li>
                </ul>
                <p>Section 2</p>
                <ul>
                    <li>5 Minutes Reading time - no writing allowed.</li>
                    <li>60 Minutes Writing time.</li>
                    <li>Once complete, take a break for exactly an hour to adhere to exam conditions.</li>
                </ul>
                <p>Section 3</p>
                <ul>
                    <li>10 Minutes Reading time - no writing allowed.</li>
                    <li>170 Minutes Writing time (2hrs 50mins).</li>
                    <li>Once finished, you have completed a full Gamsat Exam Paper - kudos!</li>
                </ul>
                <Alert variant="success">
                    <h6>Ensure you follow exam conditions:</h6>
                    <ol>
                    <li>No calculators allowed. Only a black/blue pen and pencil are allowed.</li>
                    <li>If using mobile phone as a timer, enable Do not disturb mode.</li>
                    <li>No food allowed - only a bottle of water.</li>
                    </ol>
                </Alert>
                <p>
                    In the section below we discuss how to reflect on your first run through of an exam and how to use this experience as an indicator of areas in which you are strong and areas where a sincere effort will bring up your performance.
                </p>
    
            </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
            <Accordion.Header>Reflecting on Your Performance</Accordion.Header>
            <Accordion.Body>
                <h6>After the Trial Exam</h6>
                <p>
                    If you bit the bullet an completed a trial exam, congratulations -  this is the first and most difficult step of your preparation. It’s okay to feel deflated at this stage, but you have now experienced the closest thing to what the actual exam will be like. Note your scores for both sections I & III, as well as the amount of pages you wrote per essay (we’ll discuss essay content later). This is your baseline score. Do not read into it too much as it can be disheartening if you do not perform as well as you had expected. Note that the score you receive in the exam itself is not a percentage, and in the end you will never know how well you did as it is calculated using numerous variables. On the other hand, if you happen to perform well in this you are already ahead of the curve!
                </p>
                <h6>Reflection on your First Exam</h6>
                <p>
                    It is important to make observations about the exam after you complete it. Write down how you felt — were you energised or tired, anxious or relaxed? Outline the areas of the exam you found difficult; specific topics or graphs that you had trouble navigating? Note the amount of time remaining when you finish the exam, or perhaps there will be questions you don’t get around to before the allocated time was up. These are all data which, when you think about them, have a material effect on your performance. Awareness of how the process went for you allows you to make changes to your approach with the view to improve in the future.
                </p>
                <h6>Moving Forward</h6>
                <p>
                    Having done a practice test, you will be more open to the preparation strategies and tools we refer to at YakkaGamsat. There are certain subtleties to the GAMSAT which make it different to almost all other exams. In turn, this means that the most effective preparation does not follow traditional habits. Reviewing science alone will not cut it, unfortunately, and candidates will be most aware of this once they have done a trial run.
                </p>

            </Accordion.Body>
        </Accordion.Item>
    </Accordion>
    <hr />
    
    
    <Row>
            <p>Now that you have a basic understanding of what the exam entails, take a deep dive into exam preparation strategies on a section-by-section basis by following the links below.</p>
            <h6>Preparation Strategies Per Section <Badge bg="secondary">Coming Soon</Badge></h6> 
            
            <Col sm={4}>
            <Card bg="light">
                 
                    <Card.Body>
                        <Card.Title>Section 1</Card.Title>
                        <Card.Text>
                            Getting to grips with what <i>Reasoning in the Humanities and Social Sciences</i> is all about.
                        </Card.Text>
                        <Link to="/dash/strategy/section1">
                            <Button variant="primary">
                                Section 1
                            </Button>
                        </Link>
                    </Card.Body>
            </Card>
            <br />
            </Col>
            <Col sm={4}>
            <Card bg="light">
                 
                    <Card.Body>
                        <Card.Title>Section 2</Card.Title>
                        <Card.Text>
                            Methods to ensure you will write excellent responses to the prompts on exam day. 
                        </Card.Text>
                        <Link to="/dash/strategy/section2">
                            <Button variant="primary">
                                Master Section 2
                            </Button>
                        </Link>
                    </Card.Body>
            </Card>
            <br />
            </Col>
            <Col sm={4}>
            <Card bg="light">
                 
                    <Card.Body>
                        <Card.Title>Section 3</Card.Title>
                        <Card.Text>
                                The dos and don'ts of preparing for the most feared section of the exam.
                        </Card.Text>
                        {/* <Link to="/dash/strategy/section3"> */}
                            <Button disabled variant="primary">
                                Coming Soon
                            </Button>
                        {/* </Link> */}
                    </Card.Body>
            </Card>
            </Col>
            </Row>

    </Container>
    </>
  )
}

export default StartingOff