import { useParams } from 'react-router-dom'
import ViewEssayForm from './ViewEssayForm'
import { useGetEssaysQuery } from './essaysApiSlice'
import { useGetUsersQuery } from '../users/usersApiSlice'
const ViewEssay = () => {
    const { id } = useParams()

    const { essay } = useGetEssaysQuery("essaysList", {
        selectFromResult: ({ data }) => ({
            essay: data?.entities[id]
        }),
    })

    const {user} = useGetUsersQuery("userList", {
        selectFromResult:({ data }) => ({
            user: data?.entities[essay.user]
        })
    })

    const content = <ViewEssayForm essay={essay} user={user}/> 

    return content
}
export default ViewEssay