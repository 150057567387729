import { createSelector, createEntityAdapter } from "@reduxjs/toolkit"
import { apiSlice } from "../../app/api/apiSlice"

const essaysAdapter = createEntityAdapter()
const initialState = essaysAdapter.getInitialState()

export const essaysApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getEssays: builder.query({
            query: () => ({ 
                url: '/essays',
                validateStatus: (response, result) => {
                    return response.status === 200 && !result.isError
            },
        }),
            transformResponse: responseData => {
                const loadedEssays = responseData.map(essay => {
                    essay.id = essay._id
                    return essay
                });
                return essaysAdapter.setAll(initialState, loadedEssays)
            },
            providesTags: (result, error, arg) => {
                if (result?.ids) {
                    return [
                        { type: 'Essay', id: 'LIST'},
                        ...result.ids.map(id => ({ type: 'Essay', id }))
                    ]
                } else return [{ type: 'Essay', id: 'LIST'}]
            }
        }),
        addNewEssay: builder.mutation({
            query: initialEssay => ({
                url: '/essays',
                method: 'POST',
                body: {
                    ...initialEssay,
                }
            }),
            invalidatesTags: [
                { type: 'Essay', id: "LIST" }
            ]
        }),
        updateEssay: builder.mutation({
            query: initialEssay => ({
                url: '/essays',
                method: 'PATCH',
                body: {
                    ...initialEssay,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Essay', id: arg.id }
            ]
        }),
        likeToggle: builder.mutation({
            query: initialEssay => ({
                url: '/essays/likes',
                method: 'PATCH',
                body: {
                    ...initialEssay,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Essay', id: arg.id }
            ]
        }),
        correctEssay: builder.mutation({
            query: initialEssay => ({
                url: '/essays/corrections',
                method: 'POST',
                body: {
                    ...initialEssay,
                }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Essay', id: arg.id }
            ]
        }),
        deleteEssay: builder.mutation({
            query: ({ id }) => ({
                url: `/essays`,
                method: 'DELETE',
                body: { id }
            }),
            invalidatesTags: (result, error, arg) => [
                { type: 'Essay', id: arg.id }
            ]
        }),
    })
})

export const {
    useGetEssaysQuery,
    useAddNewEssayMutation,
    useUpdateEssayMutation,
    useDeleteEssayMutation,
    useLikeToggleMutation,
    useCorrectEssayMutation
} = essaysApiSlice

export const selectEssaysResult = essaysApiSlice.endpoints.getEssays.select()

const selectEssaysData = createSelector(
    selectEssaysResult,
    essaysResult => essaysResult.data 
)

export const {
    selectAll: selectAllEssays,
    selectById: selectEssayById,
    selectIds: selectEssayIds
} = essaysAdapter.getSelectors(state => selectEssaysData(state) ?? initialState)